import SubmitNewsSec from 'components/SubmitNewsSec/SubmitNewsSec';
import DashboardWrapper from 'Layout/DashboardWrapper/DashboardWrapper';
import React from 'react'

const SubmitNews = () => {
  return (
    <DashboardWrapper headerTitle={"Submit News"}>
        <SubmitNewsSec headTxt='Creating a New Post'/>
    </DashboardWrapper>
  )
}

export default SubmitNews;