import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";
import Grid from "@mui/material/Grid";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import AreaChart from "components/AreaChart/AreaChart";
import BookIcon from "ui/Icons/BookIcon";
import Sound from "ui/Icons/Sound";
import CameraIcon from "ui/Icons/CameraIcon";
import TextIcon from "ui/Icons/TextIcon";
import UpTrend from "ui/Icons/UpTrend";
import DownTrendIcon from "ui/Icons/DownTrendIcon";
import LineChartDashed from "components/LinechartDashed/LinechartDashed";
import HighArr from "ui/Icons/HighArr";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { DashboardHomeWrapper } from "styles/StyledComponents/DashboardHomeWrapper";
import PendingCard from "components/PendingCard/PendingCard";
import { pendingData } from "json/mock/demo.mock";

export default function DashboardHomeEic() {
  return (
    <DashboardWrapper headerTitle={"Dashboard"} isEic>
      <DashboardHomeWrapper>
        <Box className="top_sec">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Box className="filter_card">
                <Box className="trend_score">
                  <UpTrend />
                  50%
                </Box>
                <Box>
                  <AreaChart chartColor="#16C098" />
                </Box>
                <Box className="filter_btm">
                  <span style={{ backgroundColor: "rgba(22, 192, 168, 0.2)" }}>
                    <BookIcon />
                  </span>
                  <Box className="filter_rgt">
                    <Typography variant="body1">Total News Posts</Typography>
                    <Typography variant="h4">1000+</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box className="filter_card">
                <Box className="trend_score">
                  <UpTrend />
                  40%
                </Box>
                <Box>
                  <AreaChart chartColor="#DFB54A" />
                </Box>
                <Box className="filter_btm">
                  <span style={{ backgroundColor: "rgba(223, 181, 74, 0.2)" }}>
                    <Sound />
                  </span>
                  <Box className="filter_rgt">
                    <Typography variant="body1">
                      Total News Audio Posts
                    </Typography>
                    <Typography variant="h4">250+</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box className="filter_card">
                <Box className="trend_score">
                  <UpTrend />
                  60%
                </Box>
                <Box>
                  <AreaChart chartColor="#2596BE" />
                </Box>
                <Box className="filter_btm">
                  <span style={{ backgroundColor: "#EBFBFF" }}>
                    <TextIcon />
                  </span>
                  <Box className="filter_rgt">
                    <Typography variant="body1">
                      Total News Video Posts
                    </Typography>
                    <Typography variant="h4">300+</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box className="filter_card">
                <Box className="trend_score down">
                  <DownTrendIcon />
                  10%
                </Box>
                <Box>
                  <AreaChart chartColor="#D6614C" />
                </Box>
                <Box className="filter_btm">
                  <span style={{ backgroundColor: "rgba(214, 97, 76, 0.2)" }}>
                    <CameraIcon />
                  </span>
                  <Box className="filter_rgt">
                    <Typography variant="body1">
                      Total News Audio Posts
                    </Typography>
                    <Typography variant="h4">400+</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={6}  xl={7} >
            <Box className="graph_lft cmn_wrap">
              <Typography variant="h2">Analytical Graph</Typography>
              <Box className="news_inr2">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className="graph_satck"
                >
                  <Box className="views_top">
                    <Typography variant="body1">Overall Views</Typography>
                    <Typography variant="h3">
                      30,621
                      <Typography variant="caption">
                        <HighArr />
                        13.02%
                      </Typography>
                    </Typography>
                  </Box>
                  <CustomSelect
                    initialValue="This Month"
                    label={""}
                    className="custom_sel"
                  >
                    <MenuItem value="One_day">One Day</MenuItem>
                    <MenuItem value="One_Month">One Month</MenuItem>
                    <MenuItem value="One_Year">One Year</MenuItem>
                  </CustomSelect>
                </Stack>
                <Box className="grph_inr">
                  <LineChartDashed />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6}  xl={5}  >
            <Box className="news_rgt cmn_wrap">
              <Typography variant="h2">Pending News</Typography>
              <Box className="news_rgt_wrap">
                <Box className="news_inr">
                  {pendingData.map((data, index) => (
                    <PendingCard
                      image={data.image}
                      title={data.title}
                      subTitle={data.subtitle}
                      key={index}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DashboardHomeWrapper>
    </DashboardWrapper>
  );
}
