export const ROUTES = {
  landingPage: "/",
  about: `/about`,
  contact: `/contact`,
  subscription: `/subscription`,
  payment: `/payment`,
  profile: `/profile`,
  auth: {
    signin: `/reporter/auth/login`,
    signup: `/auth/sign-up`,
    forgetPassword: `/auth/forgot-password`,
  },
  news: {
    saved: `saved-news`,
    details: `/news-details`,
    popular: `/popular-news`,
    top: `/top-news`,
  },
};
