import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AuthWrap from "components/AuthWrap/AuthWrap";
import React from "react";
import { Link } from "react-router-dom";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CheckedIcon from "ui/Icons/CheckedIcon";
import UNcheckedIcon from "ui/Icons/UNcheckedIcon";
import { primaryColors } from "Theme/_muiPalette";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";

const Login = () => {
  return (
    <AuthWrap
      headingText="Welcome to WNO"
      subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    >
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="abcd1922@gmail.com"
            isHeading
            inputHead="Email Address"
          />
        </Grid>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="************"
            isHeading
            inputHead="Password"
          />
        </Grid>
      </Grid>
      <LoginDataStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ margin: "20px 0 0" }}
          flexWrap="wrap"
        >
          <FormControlLabel
            className="checked"
            control={
              <Checkbox
                icon={<UNcheckedIcon />}
                checkedIcon={<CheckedIcon />}
                disableRipple
              />
            }
            label="Remember me"
          />
          <Link to="/auth/forgot-password" className="forgot">
            Forgot Password?
          </Link>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          className="btn_stack"
        >
          <CustomButtonPrimary variant="contained" color="primary">
            Log In
          </CustomButtonPrimary>
        </Stack>
        <Typography variant="body1" className="sing_up">
          Don’t have an account? <Link to="/auth/sign-up">Sign Up</Link>
        </Typography>
      </LoginDataStyle>
    </AuthWrap>
  );
};

export default Login;

export const LoginDataStyle = styled(Box)`
  .forgot {
    color: ${primaryColors.color565656};
    text-decoration: none;
    :hover {
      color: ${primaryColors.primary};
    }
    @media (max-width: 350px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .btn_stack {
    margin-top: 30px;
    button {
      min-width: 190px !important;
    }
  }
  .sing_up {
    margin-top: 20px;
    text-align: center;
    color: ${primaryColors.mainFontColor};
    font-size: 14px;
    a {
      color: ${primaryColors.primary};
      :hover {
        color: ${primaryColors.mainFontColor};
      }
    }
  }
`;
