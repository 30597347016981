import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6277 9.71568C11.2323 9.71568 10.9127 10.0407 10.9127 10.4429V12.7271C10.9127 13.1285 10.5924 13.4543 10.1977 13.4543H2.33252C1.93782 13.4543 1.61752 13.1285 1.61752 12.7271V3.2733C1.61752 2.87187 1.93785 2.5461 2.33252 2.5461H6.48674C6.88212 2.5461 7.20173 2.22104 7.20173 1.8189C7.20173 1.41677 6.88212 1.0917 6.48674 1.0917H2.33252C1.1499 1.09164 0.1875 2.07049 0.1875 3.2733V12.7271C0.1875 13.9299 1.1499 14.9087 2.33252 14.9087H10.1976C11.3803 14.9087 12.3427 13.9306 12.3427 12.7271V10.4429C12.3427 10.0407 12.0231 9.71568 11.6277 9.71568Z"
        fill="#2596BE"
      />
      <path
        d="M14.1066 0.999281C13.5876 0.471336 12.9097 0.205156 12.2269 0.190625C11.5083 0.174632 10.7854 0.439321 10.242 0.992732L5.40136 5.91598C4.69493 6.63739 4.30595 7.5944 4.30595 8.61102V10.24C4.30595 10.6421 4.62556 10.9672 5.02095 10.9672H6.62257C7.62216 10.9672 8.56311 10.5716 9.27311 9.85234L14.1073 4.93564C15.1727 3.85142 15.1727 2.08429 14.1066 0.999281ZM8.26285 8.82341C7.82454 9.26773 7.24109 9.5128 6.62262 9.5128H5.73598V8.61105C5.73598 7.98201 5.97693 7.3886 6.41309 6.94354L9.60419 3.69798L11.4532 5.57855L8.26285 8.82341ZM13.0956 3.90814L12.4642 4.55029L10.6152 2.66972L11.2466 2.0276C11.7571 1.50837 12.5865 1.50983 13.0956 2.0276C13.6047 2.54607 13.6047 3.38964 13.0956 3.90814Z"
        fill="#2596BE"
      />
    </svg>
  );
};

export default EditIcon;
