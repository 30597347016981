/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Button, List, ListItem, styled, Box, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { primaryColors } from "Theme/_muiPalette";
import AudioIcon from "ui/Icons/AudioIcon";
import CalenderIcon from "ui/Icons/CalenderIcon";
import CrossIcon from "ui/Icons/CrossIcon";

type BaseNewsCardProps = {
  isCrossBtn?: boolean;
  id: string;
  title?: string;
  description?: string;
  tag?: string;
  image?: string;
  audio?: any;
  iconTextName?: string; // need to change
  isApproved?: boolean;
  date?: string;
  onlyText?: boolean;
};
export default function BaseNewsCard({
  id,
  title,
  tag,
  image,
  audio,
  iconTextName,
  isApproved,
  date,
  description,
  onlyText,
  ...props
}: BaseNewsCardProps) {
  return (
    <SmallCardWrapper>
      {props?.isCrossBtn && (
        <Button className="close_btn">
          <CrossIcon />
        </Button>
      )}

      {image && (
        <figure>
          <Link to={id}>
            <img src={image} alt="news-banner" width={126} height={126} />
          </Link>
        </figure>
      )}

      <Box className={onlyText ? "only_txt" : "card_rgt"}>
        {tag ? <span className="tag">{tag}</span> : null}
        <Typography variant="h3">
          {title ? (
            <Link to={id}>{title}</Link>
          ) : (
            <Skeleton variant="text" animation="wave" height={36} />
          )}
        </Typography>
        <Box className="card_bottom">
          {description ? (
            <Typography variant="body1" className="reason">
              <Typography variant="caption">Reason: </Typography>

              {description ? (
                description
              ) : (
                <Skeleton variant="text" animation="wave" height={36} />
              )}
            </Typography>
          ) : (
            <List disablePadding>
              <ListItem disablePadding>
                <CalenderIcon />
                {date ?? "Dec 9, 24"}
              </ListItem>
              <ListItem disablePadding>
                {audio || <AudioIcon />}
                {iconTextName || "Audio"}
              </ListItem>
              {isApproved && (
                <ListItem disablePadding>
                  <Typography variant="body1" className="approved_text">
                    Approved
                  </Typography>
                </ListItem>
              )}
            </List>
          )}
        </Box>
      </Box>
    </SmallCardWrapper>
  );
}

const SmallCardWrapper = styled(Box)`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${primaryColors.colorC4C4C4} !important;
  min-height: 144px;
  :not(:last-child) {
    margin-bottom: 17px !important;
    padding-bottom: 17px !important;
  }
  @media (max-width: 599px) {
    flex-wrap: wrap;
  }
  &:last-child {
    border-bottom: 0;
  }
  figure {
    margin-right: 15px;
    width: 126px;
    height: 126px;
    line-height: 0;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: 599px) {
      width: 100%;
      margin-right: 0;
      height: 200px;
    }
    a {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h3 {
    margin: 12px 0;
    a {
      text-decoration: none;
    }
  }
  .card_rgt {
    width: calc(100% - 126px);
    &.only_txt {
      width: 100%;
    }
  }
  .tag {
    background: ${primaryColors.colorEFF8FB};
    backdrop-filter: blur(4px);
    border-radius: 4px;
    display: inline-block;
    line-height: 1.5;
    font-size: 14px;
    padding: 6px 22px;
    color: ${primaryColors.mainFontColor};
    text-transform: uppercase;
    @media (max-width: 599px) {
      margin-top: 15px;
    }
  }
  .card_bottom {
    ul {
      display: flex;
      li {
        font-size: 13px;
        width: auto;
        border-right: 1px solid #565656;
        margin-right: 16px;
        padding-right: 16px;

        &:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }
        svg {
          margin-right: 6px;
        }
      }
    }
    .reason {
      font-size: 14px;
      color: ${primaryColors.mainFontColor};
      span {
        display: inline-block;
        color: ${primaryColors.colorD6614C};
        font-size: inherit;
        font-family: "Merriweather";
        padding-right: 5px;
      }
    }
  }

  h3 {
    a {
      color: ${primaryColors.mainFontColor};
      font-family: inherit;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &:hover {
        color: ${primaryColors.primary};
      }
    }
  }
  .close_btn {
    position: absolute;
    top: 0;
    right: 0;
    min-width: auto;
    padding: 0;
  }
  .approved_text {
    color: ${primaryColors.color16C098};
    font-size: 14px;
  }
`;
