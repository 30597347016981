import React from "react";
import styled from "@emotion/styled";
import { Box, List, ListItem, Typography } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import CalenderIcon from "ui/Icons/CalenderIcon";
import ProfileIconSamll from "ui/Icons/ProfileIconSamll";
export const PendingCardWrap = styled(Box)`
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(196, 196, 196, 0.2);
  @media (max-width: 599px) {
   display: block;
   
}
  figure {
    width: 130px;
    line-height: 0;
    border-radius: 6px;
    overflow: hidden;
    @media (max-width: 599px) {
     width: 100%;
   
}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .rgt_boxes {
    width: calc(100% - 130px);
    padding-left: 12px;
    @media (max-width: 599px) {
     width: 100%;
     padding: 0;
}
    ul {
      display: flex;
      align-items: center;


      li {
        width: fit-content;
        padding: 0 15px;
        align-items: center;

        p {
          line-height: 1.5;
          padding-left: 6px;
          font-size: 14px;
        }
        :first-of-type {
          border-right: 1px solid ${primaryColors.color565656};
          padding-left: 0;

        }
      }
    }
  }
  h3 {
    margin-bottom: 10px;
  }
`;
interface cardProp {
  image: any;
  title: string;
  subTitle: string;
}
const PendingCard = ({ image, subTitle, title }: cardProp) => {
  return (
    <PendingCardWrap>
      <figure>
        <img src={image} alt="" />
      </figure>
      <Box className="rgt_boxes">
        <List>
          <ListItem disablePadding>
            <CalenderIcon />
            <Typography variant="body1">Dec 9, 24</Typography>
          </ListItem>
          <ListItem disablePadding>
            <ProfileIconSamll />
            <Typography variant="body1">Jhon Due</Typography>
          </ListItem>
        </List>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body1">{subTitle}</Typography>
      </Box>
    </PendingCardWrap>
  );
};

export default PendingCard;
