import React from "react";
import { Box, Container, styled } from "@mui/material";
import Wrapper from "Layout/Wrapper/Wrapper";
import AdvertisementSec from "components/AdvertisementSec/AdvertisementSec";
import CommonBanner from "components/CommonBanner/CommonBanner";
import NewsLetter from "components/NewsLetter/NewsLetter";
import OurMisson from "components/OurMisson/OurMisson";
import Values from "components/Values/Values";
import assest from "json/assest";

export default function About() {
  return (
    <Wrapper>
      <AboutWrap>
        <CommonBanner
          title="About Us"
          subTitle="World News Organisation (WNO), a new venture of Accolade Broadcasting Continuum Pvt. Ltd., delivers in-depth, curated global stories with the highest journalistic integrity. India's voice in global news has dawned."
        />
        <br/>
        {/* <OurMisson /> */}
        <Container fixed>
          <Box className="add_wrap">
            <AdvertisementSec img={assest.add2} />
          </Box>
        </Container>
        {/* <Values /> */}
        <NewsLetter />
      </AboutWrap>
    </Wrapper>
  );
}

const AboutWrap = styled(Box)`
  .add_wrap {
    padding-bottom: 90px;
    border-bottom: 1px solid rgb(196, 196, 196, 0.2);
    border-top: 1px solid rgb(196, 196, 196, 0.2);
    @media (max-width: 1199px) {
      padding-bottom: 60px;
    }
    @media (max-width: 599px) {
      padding-bottom: 40px;
    }
    @media (max-width: 479px) {
      padding-bottom: 30px;
    }
  }
  .addsTwo_wrap {
    border-top: 1px solid rgb(196, 196, 196, 0.1);
  }
`;
