import React from "react";

export default function CameraIcon() {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6292 3.1713C28.2178 2.9636 27.7564 2.87552 27.2974 2.91706C26.8384 2.95861 26.4003 3.12811 26.0329 3.4063C25.9842 3.44292 25.9383 3.48303 25.8954 3.5263L23.6991 5.74259C23.4354 2.53629 20.7728 0 17.5003 0H6.25009C4.59309 0.00132372 3.00431 0.660068 1.83251 1.83163C0.660717 3.00319 0.00165515 4.59184 0 6.24884L0 13.749C0 17.1953 2.80379 19.999 6.25009 19.999H17.5003C20.7866 19.999 23.4604 17.4403 23.7029 14.2152L25.8979 16.4077C25.9404 16.449 25.9841 16.4865 26.0316 16.5227C26.3996 16.8003 26.838 16.9693 27.297 17.0106C27.7561 17.0519 28.2176 16.9639 28.6292 16.7565C29.0421 16.5519 29.3894 16.2357 29.6318 15.8438C29.8742 15.4518 30.0019 14.9998 30.0005 14.539V5.39133C30.0005 4.44507 29.4754 3.5938 28.6292 3.1713ZM17.5003 17.499H6.25009C4.18256 17.499 2.50004 15.8165 2.50004 13.749V6.24884C2.50004 4.18131 4.18256 2.49879 6.25009 2.49879H17.5003C19.5678 2.49879 21.2503 4.18131 21.2503 6.24884V13.749C21.2503 15.8165 19.5678 17.499 17.5003 17.499ZM23.7504 10.7277V9.24139L27.5004 5.45633L27.5317 14.504L23.7504 10.7264V10.7277Z"
        fill="#D6614C"
      />
    </svg>
  );
}
