import DashboardWrapper from 'Layout/DashboardWrapper/DashboardWrapper'
import EicProfileCont from 'components/EicProfile/EicProfile'
import React from 'react'

const EicProfile = () => {
  return (
    <DashboardWrapper headerTitle={"My Profile"} isEic >
     <EicProfileCont/>
    </DashboardWrapper>
  )
}

export default EicProfile