import React, { FC } from "react";
import {
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import assest from "json/assest";
import { NewsDetilsUPWrap } from "styles/StyledComponents/NewsDetilsUPWrap";
import Calender from "ui/Icons/Calender";
import Music from "ui/Icons/Music";
import SavedIcon from "ui/Icons/SavedIcon";
import Share from "ui/Icons/Share";

type NewsDetailsProps = {
  title?: string;
  description?: string;
  createdBy?: string;
  creationDate?: string;
  tag?: string;
  image?: string;
  newsCourtesy?: string;
};
const NewsDetils: FC<NewsDetailsProps> = ({
  title,
  description,
  createdBy,
  creationDate,
  tag,
  image,
  newsCourtesy
}) => {
  return (
    <NewsDetilsUPWrap>
      <Container fixed>
        <Typography variant="h2">
          {title || <Skeleton variant="text" animation="wave" />}
        </Typography>
        {/* <Typography variant="body1">{description}</Typography> */}
        <Typography
                  variant="body1"
                  className="long_texts"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      Number(description?.length) >= 3500
                        ? `${description?.slice(0, 3500)}...`
                        : description
                    }`,
                  }}
                />
        {/* <Typography variant="h3">By: {createdBy}</Typography> */}
        <figure>
          <img src={image} alt="" />
          {/* Add the image courtesy below the image */}
          {newsCourtesy ? (
                  <figcaption>
                    <Typography variant="caption" display="block" color="textSecondary" marginTop={1}>
                      Image courtesy: {newsCourtesy}
                    </Typography>
                  </figcaption>
                ):(
                  <figcaption>
                    <Typography variant="caption" display="block" color="textSecondary" marginTop={1}>
                    Image courtesy: Image courtesy of our news partners.
                    </Typography>
                  </figcaption>
                )}
        </figure>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="left_box"
          flexWrap="wrap"
        >
          <Box className="muisc_box">
            <Typography variant="body1">
              <Calender />
              {creationDate ?? "December 9, 2024"}
            </Typography>
            <Typography variant="body1">{tag}</Typography>
            <Typography variant="body1">
              <Music />
              Audio
            </Typography>
          </Box>
          <Stack className="btn_stack" direction="row" alignItems="center">
            <Button variant="text" startIcon={<Share />}>
              Share
            </Button>
            <Button variant="text" startIcon={<SavedIcon />}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Container>
    </NewsDetilsUPWrap>
  );
};

export default NewsDetils;
