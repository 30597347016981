/* eslint-disable jsx-a11y/anchor-is-valid */

import { Skeleton, styled } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { primaryColors } from "Theme/_muiPalette";
import AudioIcon from "ui/Icons/AudioIcon";
import CalenderIcon from "ui/Icons/CalenderIcon";

type CommonCardProps = {
  id: string;
  title?: string;
  description?: string;
  tag?: string;
  image?: string;
  audio?: any;
  date?: string;
};
export default function CommonCard({
  id,
  image,
  tag,
  title,
  description,
  date,
}: CommonCardProps) {
  return (
    <CmnCardWrapper className="card_wrap">
      <figure>
        <Link to="/">
          <img alt="" src={image} width={266} height={175} />
          <span className="tag">{tag}</span>
        </Link>
      </figure>
      <Box className="card_top">
        <List disablePadding>
          <ListItem disablePadding>
            <CalenderIcon />
            {date}
          </ListItem>
          <ListItem disablePadding>
            <AudioIcon />
            Audio
          </ListItem>
        </List>
      </Box>
      <Typography variant="h3">
        {title ? (
          <Link to={id}>{title}</Link>
        ) : (
          <Skeleton variant="text" height={36} animation="wave" />
        )}
      </Typography>
      {/* <Typography variant="body1">
        {description ? (
          description
        ) : (
          <Skeleton variant="text" height={36} animation="wave" />
        )}
      </Typography> */}
      <Typography
                  variant="body1"
                  className="long_texts"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      Number(description?.length) >= 150
                        ? `${description?.slice(0, 150)}...`
                        : description
                    }`,
                  }}
                />
    </CmnCardWrapper>
  );
}

const CmnCardWrapper = styled(Box)`
  figure {
    width: 100%;
    line-height: 1;
    position: relative;
    height: 175px;
    border-radius: 6px;
    overflow: hidden;
    a {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .tag {
    background: rgba(45, 45, 45, 0.2);
    backdrop-filter: blur(4px);
    line-height: 1.8;
    border-radius: 4px;
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 12px;
    padding: 6px 22px;
    color: ${primaryColors.white};
    text-transform: uppercase;
  }
  p {
    color: ${primaryColors.textTeriaryColor};
    margin-top: 10px;
    word-break: break-all;
  }
  .card_top {
    margin: 20px 0;
    ul {
      display: flex;
      li {
        font-size: 14px;
        width: auto;
        border-right: 1px solid #565656;
        margin-right: 16px;
        padding-right: 16px;
        &:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }
        svg {
          margin-right: 6px;
        }
      }
    }
  }
  h3 {
    a {
      word-break: break-word;

      color: ${primaryColors.mainFontColor};
      &:hover {
        color: ${primaryColors.primary};
      }
    }
  }
`;
