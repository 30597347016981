import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import MuiModalWrapper from 'components/CommonModal/CommonModal'
import assest from 'json/assest'
import React, { useState } from 'react'
import { EicProfileWrap } from 'styles/StyledComponents/EicProfileWrap'
import { ChangePassWord } from 'styles/StyledComponents/ModalStyled'
import InputFieldCommon from 'ui/CommonInput/CommonInput'
import CustomButtonPrimary from 'ui/CustomButtons/CustomButtonPrimary'
import EditIcon from 'ui/Icons/EditIcon'

const EicProfileCont = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(!open);
    };
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <EicProfileWrap className='cmn_body_white'>
        <Box className="lftBox">

            <Box className="figure_wrap">

            <figure>
                <img src={assest.myProfileImg} alt="" />
            </figure>
            </Box>
            <Typography variant='h3'>
            Jhon Doe
            </Typography>
        </Box>
        <Box className="rgtBox">
            <Stack direction="row" alignItems="center" justifyContent="space-between" className='heading_stack'>
            <Typography variant='h2'>
            My Information
            </Typography>
            <Button className="edit_butt">
                <EditIcon />
              </Button>
            </Stack>
            <Box className="form">
                <Grid container spacing={2.5}>
                    <Grid item xs={12} md={6}>
                        <InputFieldCommon isHeading inputHead='First Name' placeholder='Jhon'/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputFieldCommon isHeading inputHead='Last Name' placeholder='Doe'/>
                    </Grid>
                    <Grid item xs={12} >
                        <InputFieldCommon isHeading inputHead='Mobile Number' placeholder='(702) 555-0122'/>
                    </Grid>
                    <Grid item xs={12} >
                        <InputFieldCommon isHeading inputHead='City' placeholder='Quigleybury'/>
                    </Grid>
                    <Grid item xs={12} >
                        <InputFieldCommon isHeading inputHead='State' placeholder='Montana'/>
                    </Grid>
                </Grid>
                <CustomButtonPrimary color='primary' variant='contained' className='passWord_Butt'    onClick={handleOpen}>
                Change Password
                </CustomButtonPrimary>
            </Box>
        </Box>
        <MuiModalWrapper onClose={handleClose} open={open} isClose>
        <ChangePassWord>
          <Typography variant="h3">Change Password</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputFieldCommon
                placeholder="************"
                isHeading
                inputHead="Old Password"
              />
            </Grid>
            <Grid item xs={12}>
              <InputFieldCommon
                placeholder="************"
                isHeading
                inputHead="New Password"
              />
            </Grid>
            <Grid item xs={12}>
              <InputFieldCommon
                placeholder="************"
                isHeading
                inputHead="Confirm Password"
              />
            </Grid>
          </Grid>
          <CustomButtonPrimary
            variant="contained"
            color="primary"
            className="modla_button"
            fullWidth
          >
            Save
          </CustomButtonPrimary>
        </ChangePassWord>
      </MuiModalWrapper>
    </EicProfileWrap>
  )
}

export default EicProfileCont