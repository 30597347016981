import React from "react";

const BlueTickIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_123_38)">
        <path
          d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
          fill="#2596BE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.18712 13.3937L2.90601 9.85746C2.68835 9.62423 2.57207 9.31419 2.5827 8.99535C2.59333 8.6765 2.72999 8.37489 2.96271 8.15667C3.19685 7.9399 3.50743 7.82487 3.82629 7.83683C4.14515 7.84879 4.44624 7.98675 4.66349 8.22045L7.2005 10.9346L11.2186 7.17872C11.2545 7.14575 11.2924 7.11498 11.332 7.0866L13.217 5.32203C13.4503 5.10436 13.7603 4.98809 14.0792 4.99872C14.398 5.00934 14.6996 5.14601 14.9178 5.37872C15.1355 5.61195 15.2518 5.92199 15.2411 6.24084C15.2305 6.55968 15.0938 6.86129 14.8611 7.07951L9.08554 12.4653L7.08003 14.3291L6.1942 13.3795L6.18712 13.3937Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_123_38">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlueTickIcon;
