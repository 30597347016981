import React from "react";

const THreeDots = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#F2F2F2" />
      <path
        d="M10.0625 11C10.0625 11.5169 10.4831 11.9375 11 11.9375C11.5169 11.9375 11.9375 11.5169 11.9375 11C11.9375 10.4831 11.5169 10.0625 11 10.0625C10.4831 10.0625 10.0625 10.4831 10.0625 11ZM10.0625 14.125C10.0625 14.6419 10.4831 15.0625 11 15.0625C11.5169 15.0625 11.9375 14.6419 11.9375 14.125C11.9375 13.6081 11.5169 13.1875 11 13.1875C10.4831 13.1875 10.0625 13.6081 10.0625 14.125ZM10.0625 7.875C10.0625 8.39188 10.4831 8.8125 11 8.8125C11.5169 8.8125 11.9375 8.39188 11.9375 7.875C11.9375 7.35812 11.5169 6.9375 11 6.9375C10.4831 6.9375 10.0625 7.35812 10.0625 7.875Z"
        fill="#060606"
      />
    </svg>
  );
};

export default THreeDots;
