import React from "react";

const PhoneBig = () => {
  return (
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.3331 51.1713C61.3331 52.1763 61.1097 53.2092 60.6352 54.2142C60.1606 55.2192 59.5464 56.1683 58.7368 57.0617C57.3689 58.5692 55.8614 59.6579 54.1585 60.3558C52.4835 61.0538 50.6689 61.4167 48.7147 61.4167C45.8672 61.4167 42.8243 60.7467 39.6139 59.3788C36.4035 58.0108 33.1931 56.1683 30.0106 53.8513C26.8002 51.5063 23.7572 48.91 20.8539 46.0346C17.9785 43.1313 15.3822 40.0883 13.0652 36.9058C10.776 33.7233 8.9335 30.5408 7.5935 27.3863C6.2535 24.2038 5.5835 21.1608 5.5835 18.2575C5.5835 16.3592 5.9185 14.5446 6.5885 12.8696C7.2585 11.1667 8.31933 9.60334 9.79891 8.20751C11.5856 6.44876 13.5397 5.58334 15.6056 5.58334C16.3872 5.58334 17.1689 5.75084 17.8668 6.08584C18.5927 6.42084 19.2347 6.92334 19.7372 7.64918L26.2139 16.7779C26.7164 17.4758 27.0793 18.1179 27.3306 18.7321C27.5818 19.3183 27.7214 19.9046 27.7214 20.435C27.7214 21.105 27.526 21.775 27.1352 22.4171C26.7722 23.0592 26.2418 23.7292 25.5718 24.3992L23.4502 26.6046C23.1431 26.9117 23.0035 27.2746 23.0035 27.7213C23.0035 27.9446 23.0314 28.14 23.0872 28.3633C23.171 28.5867 23.2547 28.7542 23.3106 28.9217C23.8131 29.8429 24.6785 31.0433 25.9068 32.495C27.1631 33.9467 28.5031 35.4263 29.9547 36.9058C31.4622 38.3854 32.9139 39.7533 34.3935 41.0096C35.8452 42.2379 37.0456 43.0754 37.9947 43.5779C38.1343 43.6338 38.3018 43.7175 38.4972 43.8013C38.7206 43.885 38.9439 43.9129 39.1952 43.9129C39.6697 43.9129 40.0327 43.7454 40.3397 43.4383L42.4614 41.3446C43.1593 40.6467 43.8293 40.1163 44.4714 39.7813C45.1135 39.3904 45.7556 39.195 46.4535 39.195C46.9839 39.195 47.5422 39.3067 48.1564 39.5579C48.7706 39.8092 49.4127 40.1721 50.1106 40.6467L59.351 47.2071C60.0768 47.7096 60.5793 48.2958 60.8864 48.9938C61.1656 49.6917 61.3331 50.3896 61.3331 51.1713Z"
        stroke="#2596BE"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M51.6458 25.125C51.6458 23.45 50.3337 20.8817 48.3796 18.7879C46.5929 16.8617 44.22 15.3542 41.875 15.3542"
        stroke="#2596BE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.4167 25.125C61.4167 14.3213 52.6787 5.58334 41.875 5.58334"
        stroke="#2596BE"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneBig;
