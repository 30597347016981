import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7825 18.6042L14.8083 13.63C16.1639 11.9722 16.8303 9.85675 16.6699 7.72131C16.5094 5.58587 15.5344 3.59378 13.9463 2.15711C12.3583 0.720433 10.2788 -0.050916 8.13805 0.0026105C5.99725 0.056137 3.95893 0.930444 2.44469 2.44469C0.930444 3.95893 0.056137 5.99725 0.0026105 8.13805C-0.050916 10.2788 0.720433 12.3583 2.15711 13.9463C3.59378 15.5344 5.58587 16.5094 7.72131 16.6699C9.85675 16.8303 11.9722 16.1639 13.63 14.8083L18.6042 19.7825C18.7614 19.9343 18.9719 20.0183 19.1904 20.0164C19.4088 20.0145 19.6179 19.9269 19.7724 19.7724C19.9269 19.6179 20.0145 19.4088 20.0164 19.1904C20.0183 18.9719 19.9343 18.7614 19.7825 18.6042ZM8.36001 15.0267C7.04147 15.0267 5.75254 14.6357 4.65621 13.9031C3.55988 13.1706 2.7054 12.1294 2.20081 10.9112C1.69623 9.69306 1.5642 8.35262 1.82144 7.05941C2.07867 5.7662 2.71361 4.57831 3.64596 3.64596C4.57831 2.71361 5.7662 2.07867 7.05941 1.82144C8.35262 1.5642 9.69306 1.69623 10.9112 2.20081C12.1294 2.7054 13.1706 3.55988 13.9031 4.65621C14.6357 5.75254 15.0267 7.04147 15.0267 8.36001C15.0247 10.1275 14.3217 11.8221 13.0719 13.0719C11.8221 14.3217 10.1275 15.0247 8.36001 15.0267Z"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export default SearchIcon;
