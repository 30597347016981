import React from "react";

const SavedIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0837 8.875H7.91699"
        stroke="#2D2D2D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0166 1.66669H5.98327C4.20827 1.66669 2.7666 3.11669 2.7666 4.88335V16.625C2.7666 18.125 3.8416 18.7584 5.15827 18.0334L9.22494 15.775C9.65827 15.5334 10.3583 15.5334 10.7833 15.775L14.8499 18.0334C16.1666 18.7667 17.2416 18.1334 17.2416 16.625V4.88335C17.2333 3.11669 15.7916 1.66669 14.0166 1.66669Z"
        stroke="#2D2D2D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SavedIcon;
