import React from "react";
import { Box, Container, styled } from "@mui/material";
import Wrapper from "Layout/Wrapper/Wrapper";
import AdvertisementSec from "components/AdvertisementSec/AdvertisementSec";
import DetailsMod from "components/DetailsMod/DetailsMod";
import MoreNews from "components/MoreNews/MoreNews";
import NewsLetter from "components/NewsLetter/NewsLetter";
import assest from "json/assest";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
//import { useQuery } from "react-query";
import { getNewsDetails } from "api/function/news.api";
import NewsDetils from "components/NewsDetilsUP/NewsDetilsUP";
import dayjs from "dayjs";

const NewsDetails = () => {
  let location = useLocation();
  let urlParams = useParams<{ id?: string }>();
  let [urlSearchParams] = useSearchParams();


  // const { data: NewsDetails } = useQuery({
  //   queryKey: ["get-news-details", urlParams?.id],
  //   queryFn: () => getNewsDetails(urlParams.id!),
  //   enabled: !!urlParams.id,
  //   select(data) {
  //     return data.data;
  //   },
  // });

  const [NewsDetails, setNewsDetails] = React.useState<any>();

  React.useEffect(() => {
    console.log("Enter")
      // Scroll to the top whenever the route (id) changes
      window.scrollTo(0, 0);
    // Fetch News using fetch() method
    const fetchData = async () => {
      const data = await fetch("https://api.ascendworld.info/api/v1/news/"+urlParams.id);
      const news_data = await data.json();
      setNewsDetails(news_data.data);
    }
    fetchData();
  },[urlParams.id])
  return (
    <Wrapper>
      <NewsDetils
        title={NewsDetails?.title}
        description={NewsDetails?.content}
        createdBy={NewsDetails?.NewsUserRelation?.full_name}
        creationDate={dayjs(NewsDetails?.createdAt).format("MMMM DD, YYYY")}
        tag={NewsDetails?.category ?? ""}
        image={
          NewsDetails?.newsImage
            ? NewsDetails?.newsImage
            : assest.cardImage7
        }
        newsCourtesy = {NewsDetails?.newsCourtesy}
      />
      <Container fixed>
        <AboutWrap>
          <Box className="add_wrap">
            <AdvertisementSec img={assest.advertiseImage} />
          </Box>
        </AboutWrap>
      </Container>
      {/* x */}
      <MoreNews />

      <NewsLetter />
    </Wrapper>
  );
};

export default NewsDetails;

const AboutWrap = styled(Box)`
  .add_wrap {
    padding-bottom: 90px;
    border-bottom: 1px solid rgb(196, 196, 196, 0.2);
    border-top: 1px solid rgb(196, 196, 196, 0.2);
    @media (max-width: 1199px) {
      padding-bottom: 60px;
    }
    @media (max-width: 599px) {
      padding-bottom: 40px;
    }
    @media (max-width: 479px) {
      padding-bottom: 30px;
    }
  }
  .addsTwo_wrap {
    border-top: 1px solid rgb(196, 196, 196, 0.1);
  }
`;
