import React from "react";

const CheckedIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="15"
        height="14"
        rx="2"
        transform="matrix(1 0 0 -1 0 14)"
        fill="#30B0C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3152 3.67066C12.4323 3.77997 12.498 3.92821 12.498 4.08277C12.498 4.23733 12.4323 4.38557 12.3152 4.49487L6.38217 10.0324C6.26505 10.1416 6.10623 10.203 5.94063 10.203C5.77503 10.203 5.6162 10.1416 5.49909 10.0324L2.68871 7.40934C2.62906 7.35557 2.58148 7.29125 2.54875 7.22014C2.51602 7.14902 2.49879 7.07253 2.49807 6.99514C2.49735 6.91774 2.51315 6.84099 2.54455 6.76935C2.57596 6.69772 2.62233 6.63264 2.68097 6.57791C2.73961 6.52318 2.80934 6.47989 2.88609 6.45059C2.96284 6.42128 3.04508 6.40653 3.128 6.4072C3.21093 6.40787 3.29288 6.42395 3.36907 6.4545C3.44527 6.48505 3.51418 6.52946 3.57179 6.58513L5.94063 8.79605L11.4321 3.67066C11.5492 3.56139 11.708 3.5 11.8737 3.5C12.0393 3.5 12.1981 3.56139 12.3152 3.67066Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedIcon;
