import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography, styled } from "@mui/material"
import { primaryColors } from "Theme/_muiPalette"
import AuthWrap from "components/AuthWrap/AuthWrap"
import MuiModalWrapper from "components/CommonModal/CommonModal"
import assest from "json/assest"
import { useState } from "react"
import { Link } from "react-router-dom"
import { LinkModalWrap } from "styles/StyledComponents/ModalStyled"
import InputFieldCommon from "ui/CommonInput/CommonInput"
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary"
import CheckedIcon from "ui/Icons/CheckedIcon"
import UNcheckedIcon from "ui/Icons/UNcheckedIcon"

export const LoginWrap = styled(Box)`
.button_primary{
    min-width: 190px !important;
    margin-top: 30px;
}
.sing_up{
    margin-top: 20px;
    text-align: center;
    color: ${primaryColors.mainFontColor};
    font-size: 14px;
    a{
        color: ${primaryColors.primary};
        :hover{
            color: ${primaryColors.mainFontColor};
        }
    }
}
.checked{
    margin-top: 20px;
}

`
const SignUp = () => {
    const [open,setOpen]=useState(false)
    const handleOpen = ()=>{
        setOpen(!open)
    }
    const handleClose =()=>{
        setOpen(false)
    }
  return (
    <AuthWrap headingText="Welcome to WNO" subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit">
        <LoginWrap>
        <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
                <InputFieldCommon placeholder="Jhon" isHeading inputHead="First Name"/>
            </Grid>
            <Grid item xs={12} md={6}>
                <InputFieldCommon placeholder="Doe" isHeading inputHead="Last Name"/>
            </Grid>
            <Grid item xs={12} md={12}>
                <InputFieldCommon placeholder="abcd1922@gmail.com" isHeading inputHead="Email Address"/>
            </Grid>
            <Grid item xs={12} md={12}>
                <InputFieldCommon placeholder="(702) 555-0122" isHeading inputHead="Mobile Number"/>
            </Grid>
            <Grid item xs={12} md={12}>
                <InputFieldCommon placeholder="************" isHeading inputHead="Password"/>
            </Grid>
            <Grid item xs={12} md={12}>
                <InputFieldCommon placeholder="************" isHeading inputHead="Confirm Password"/>
            </Grid>
        </Grid>
        <FormControlLabel
        className="checked"
              control={
                <Checkbox
                  icon={<UNcheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  disableRipple
                />
              }
              label="Accept terms & conditions and privacy policy"
            />
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CustomButtonPrimary variant="contained" color="primary" className="button_primary" onClick={handleOpen}>
                Sign Up
                </CustomButtonPrimary>
            </Stack>
            <Typography variant="body1" className="sing_up">
            -If already have an account? <Link to="/auth/login">Log In</Link>
            </Typography>
            </LoginWrap>
            <MuiModalWrapper onClose={handleClose} open={open} isClose>
                <LinkModalWrap>
                <i>
                    <img src={assest.mail_icon} alt="" />
                </i>
                <Typography variant="h3">
                We have sent a link to your email 
                </Typography>
                <Typography variant="body1">
                Lorem ipsum dolor sit amet consectetur. Congue sed sed eget id blandit pretium penatibus quam.
                </Typography>
                <CustomButtonPrimary variant="contained" color="primary" className="modla_button">
                Continue
                </CustomButtonPrimary>
                </LinkModalWrap>
            </MuiModalWrapper>
    </AuthWrap>
  
  )
}

export default SignUp