import { Box, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const RecentNewsWrapper = styled(Box)`
  padding-top: 90px;
  @media (max-width: 1199px) {
    padding-top: 60px;
  }
  @media (max-width: 599px) {
    padding-top: 40px;
  }
  @media (max-width: 479px) {
    padding-top: 30px;
  }
  .podcast_in_new {
    figure {
      height: 330px;
      a {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
    .tag {
      background: ${primaryColors.colorEFF8FB};
      backdrop-filter: blur(4px);
      border-radius: 4px;
      display: inline-block;
      margin: 15px 0;
      font-size: 12px;
      padding: 6px 22px;
      color: ${primaryColors.mainFontColor};
      text-transform: uppercase;
    }
    h3 {
      font-size: 24px;

      a {
        color: ${primaryColors.mainFontColor};
        &:hover {
          color: ${primaryColors.primary};
        }
      }
    }
    p {
      font-size: 15px;
      color: ${primaryColors.textTeriaryColor};
      margin-bottom: 20px;
      word-break: break-all;
    }
  }
  .card_bottom {
    ul {
      display: flex;
      margin: 15px 0;
      li {
        font-size: 16px;
        width: auto;
        border-right: 1px solid #565656;
        margin-right: 16px;
        padding-right: 16px;
        &:last-child {
          border-right: 0;
          margin-right: 0;
          padding-right: 0;
        }
        svg {
          margin-right: 6px;
        }
      }
    }
  }
  .recnt_hdr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${primaryColors.colorC4C4C4};
    margin-bottom: 25px;
    padding-bottom: 25px;
    button {
      min-width: auto;
      padding: 0;
    }
    h2 {
      font-size: 24px;
      color: ${primaryColors.primary};
    }
  }
`;
