import { Box, styled } from "@mui/material";

export const CmnNewsDetailsStyled = styled(Box)`
    .newsDetails{
    }
    .newsPara{
        p{

            max-width: 571px;
            padding-right: 40px;
            @media (max-width: 899px) {
                max-width: 100%;
                padding-right: 0;
            }
        }
        :last-child{
            p{
                padding-left: 40px;
                padding-right: 0;
                @media (max-width: 899px) {
                max-width: 100%;
                padding-left: 0;
            }
            }
        }
    }
    .newsDetilsImg{
        border-radius: 6px;
        overflow: hidden;
        line-height: 0;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

`