import { Box, Container, styled, Typography } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
//import images from "../../assets/WNO Tag 400x75.png";
import images from "../../assets/wnoBrand.png";

/**
 * @author Sourav Bera(ascend.dev02@accoladeknowledge.com)
 * @version 13092024
 * @copyright AccoladeBroadcastingContinumPrivateLimited2024
 * @function BigText
 * @description A functional component that becomes sticky when it reaches the top during scroll.
 * @returns {JSX.Element} The rendered BigText component.
 */
export const BigTextWrap = styled(Box)`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0; /* Stick at the top */
  z-index: 1000; /* Keeps it on top of other elements */
  background-color: white; /* Make sure it's visible */
  padding: 0 0;
  
  @media (max-width: 899px) {
    padding: 15px 0;
  }
  
  p {
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'bebas_kairegular';
    font-weight: 400;
    color: ${primaryColors.primary};
    
    @media (max-width: 599px) {
      font-size: 30px;
    }
  }
`;

const BigText = () => {
  return (
    <BigTextWrap>
      <Container fixed style={{ display: "flex", justifyContent: "center" }}>
        <img src={images} alt="big_text" style={{width:"400px"}}/>
      </Container>
      <div style={{textAlign:"center", paddingBottom:"5px"}}>
      <span style={{textAlign:"center", color:"#2696BE"}}>When Truth Becomes News</span>
      </div>
      
    </BigTextWrap>
  );
};

export default BigText;
