import NewsListSec from "components/NewsListSec/NewsListSec";
import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";
import React from "react";

const NewsDetailsList = () => {
  return (
    <DashboardWrapper headerTitle={"News Management"} isEic>
      <NewsListSec />
    </DashboardWrapper>
  );
};

export default NewsDetailsList;
