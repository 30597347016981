import MyProfileSec from "components/MyProfileSec/MyProfileSec";
import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";
import React from "react";

const DashBoardMyProfile = () => {
  return (
    <DashboardWrapper headerTitle={"My Profile"} >
      <MyProfileSec />
    </DashboardWrapper>
  );
};

export default DashBoardMyProfile;
