import SubmitNewsSec from "components/SubmitNewsSec/SubmitNewsSec";
import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";
import React from "react";

const NewsDetailsEdit = () => {
  return (
    <DashboardWrapper headerTitle={"News Management"}>
      <SubmitNewsSec headTxt="Edit News" />
    </DashboardWrapper>
  );
};

export default NewsDetailsEdit;
