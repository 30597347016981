import React, { useState } from "react";
import { Box, styled, Container, Typography, Grid } from "@mui/material";
import MuiModalWrapper from "components/CommonModal/CommonModal";
import assest from "json/assest";
import Wrapper from "Layout/Wrapper/Wrapper";
import { ChangePassWord } from "styles/StyledComponents/ModalStyled";
import { primaryColors } from "Theme/_muiPalette";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";

export default function MyProfile() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper>
      <MyProfileWrap>
        <Container fixed>
          <Box className="profile_outr">
            <Box className="profile_hdr">
              <Typography variant="h2">My Profile</Typography>
              <Typography variant="body1">
                Morbi id convallis nunc. Pellentesque vehicula velit ac velit
                gravida, non consectetur leo varius. Phasellus et libero congue,
                cursus justo at, faucibus dui.{" "}
              </Typography>
            </Box>

            <Box className="profile_card">
              <figure className="profile_img">
                <img src={assest.userImage} alt="" />
              </figure>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputFieldCommon
                    placeholder="Jhon"
                    isHeading
                    inputHead="First Name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputFieldCommon
                    placeholder="Doe"
                    isHeading
                    inputHead="Last Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputFieldCommon
                    placeholder="abcd1922@gmail.com"
                    isHeading
                    inputHead="Email Address"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputFieldCommon
                    placeholder="(702) 555-0122"
                    isHeading
                    inputHead="Mobile Number"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputFieldCommon
                    placeholder="Quigleybury"
                    isHeading
                    inputHead="City"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputFieldCommon
                    placeholder="Montana"
                    isHeading
                    inputHead="State"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomButtonPrimary
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Edit Profile
                  </CustomButtonPrimary>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomButtonPrimary
                    variant="outlined"
                    color="inherit"
                    fullWidth
                    onClick={handleOpen}
                  >
                    Change Password
                  </CustomButtonPrimary>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </MyProfileWrap>
      <MuiModalWrapper onClose={handleClose} open={open} isClose>
        <ChangePassWord>
          <Typography variant="h3">Change Password</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputFieldCommon
                placeholder="************"
                isHeading
                inputHead="Old Password"
              />
            </Grid>
            <Grid item xs={12}>
              <InputFieldCommon
                placeholder="************"
                isHeading
                inputHead="New Password"
              />
            </Grid>
            <Grid item xs={12}>
              <InputFieldCommon
                placeholder="************"
                isHeading
                inputHead="Confirm Password"
              />
            </Grid>
          </Grid>
          <CustomButtonPrimary
            variant="contained"
            color="primary"
            className="modla_button"
            fullWidth
          >
            Save
          </CustomButtonPrimary>
        </ChangePassWord>
      </MuiModalWrapper>
    </Wrapper>
  );
}

const MyProfileWrap = styled(Box)`
  padding: 100px 0;
  @media (max-width: 1199px) {
    padding: 80px 0px;
  }
  @media (max-width: 899px) {
    padding: 60px 0px;
  }
  @media (max-width: 599px) {
    padding: 50px 0px;
  }
  .profile_card {
    background: ${primaryColors.white};
    box-shadow: 0px 4px 20px rgba(37, 150, 190, 0.12);
    border-radius: 10px;
    max-width: 790px;
    margin: auto;
    padding: 75px 110px;
    @media (max-width: 1199px) {
      padding: 45px 50px;
    }
    @media (max-width: 599px) {
      padding: 35px 30px;
    }
  }
  .profile_img {
    width: 135px;
    height: 135px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .profile_hdr {
    max-width: 620px;
    margin: 0 auto 50px;
    text-align: center;
    h2 {
      font-size: 30px;
    }
  }
`;
