export default function InstsIcon() {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 16.4561C15.5857 16.4561 16.8711 15.2165 16.8711 13.6875C16.8711 12.1585 15.5857 10.9189 14 10.9189C12.4143 10.9189 11.1289 12.1585 11.1289 13.6875C11.1289 15.2165 12.4143 16.4561 14 16.4561Z"
        fill="white"
      />
      <path
        d="M14 0.503906C6.44924 0.503906 0.328125 6.40641 0.328125 13.6875C0.328125 20.9686 6.44924 26.8711 14 26.8711C21.5508 26.8711 27.6719 20.9686 27.6719 13.6875C27.6719 6.40641 21.5508 0.503906 14 0.503906ZM22.4469 17.0382C22.3813 18.2916 22.016 19.5276 21.0688 20.4313C20.1124 21.3436 18.8245 21.6836 17.5122 21.7462H10.4879C9.17536 21.6836 7.88769 21.3438 6.93126 20.4313C5.98396 19.5276 5.61876 18.2916 5.55313 17.0382V10.3368C5.61876 9.08337 5.98402 7.84738 6.93126 6.94362C7.88769 6.03131 9.17552 5.69139 10.4879 5.62879H17.5121C18.8246 5.69139 20.1123 6.03115 21.0687 6.94362C22.016 7.84738 22.3812 9.08337 22.4469 10.3368L22.4469 17.0382Z"
        fill="white"
      />
      <path
        d="M17.4235 7.15576C15.7125 7.11051 12.288 7.11051 10.577 7.15576C9.68665 7.17933 8.67728 7.39307 8.04291 8.04644C7.3837 8.72561 7.1346 9.54616 7.10934 10.4641C7.06493 12.0752 7.10934 16.911 7.10934 16.911C7.13827 17.8288 7.3837 18.6495 8.04291 19.3286C8.67728 19.9822 9.68665 20.1958 10.577 20.2193C12.288 20.2646 15.7125 20.2646 17.4235 20.2193C18.3138 20.1958 19.3232 19.982 19.9576 19.3286C20.6168 18.6495 20.8659 17.8289 20.8911 16.911V10.4641C20.8659 9.54616 20.6168 8.72561 19.9576 8.04644C19.323 7.39285 18.3136 7.17933 17.4235 7.15576ZM14 17.9788C13.1198 17.9788 12.2594 17.7271 11.5276 17.2556C10.7958 16.7841 10.2254 16.1139 9.88857 15.3297C9.55175 14.5456 9.46362 13.6828 9.63533 12.8504C9.80704 12.0179 10.2309 11.2533 10.8533 10.6532C11.4756 10.053 12.2686 9.64432 13.1318 9.47874C13.9951 9.31316 14.8899 9.39814 15.703 9.72294C16.5162 10.0477 17.2112 10.5978 17.7002 11.3034C18.1892 12.0091 18.4502 12.8388 18.4502 13.6875C18.4502 14.8257 17.9814 15.9172 17.1468 16.7219C16.3122 17.5267 15.1803 17.9788 14 17.9788ZM18.4691 10.2821C18.2931 10.282 18.121 10.2317 17.9747 10.1373C17.8284 10.043 17.7143 9.90894 17.647 9.75211C17.5797 9.59528 17.5621 9.42272 17.5964 9.25625C17.6308 9.08978 17.7156 8.93687 17.8401 8.81686C17.9646 8.69686 18.1231 8.61514 18.2958 8.58204C18.4684 8.54894 18.6474 8.56595 18.81 8.63091C18.9726 8.69588 19.1116 8.80588 19.2094 8.94702C19.3072 9.08815 19.3594 9.25408 19.3594 9.42381C19.3594 9.53654 19.3364 9.64816 19.2916 9.7523C19.2469 9.85644 19.1813 9.95106 19.0986 10.0308C19.016 10.1105 18.9178 10.1737 18.8098 10.2168C18.7018 10.2599 18.586 10.2821 18.4691 10.2821Z"
        fill="white"
      />
    </svg>
  );
}
