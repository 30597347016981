import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
//import { useQuery } from "react-query";
import { QUERY_KEYS } from "utils/config/queryKeys.config";
import { NEWS_TYPE } from "utils/config/news.config";
import { getNews } from "api/function/news.api";
import dayjs from "dayjs";
import BaseNewsCard from "../Cards/BaseNewsCard";
import CommonCard from "../Cards/CommonCard";
import { MEDIA_URL_SERVER2 } from "utils/config/common.config";
import assest from "json/assest";
import { ROUTES } from "utils/config/routePaths.config";
import React from "react";

export default function PodcastSec() {
  // const { data: PodcastNewsList } = useQuery({
  //   queryKey: [QUERY_KEYS.news.list, NEWS_TYPE.PODCAST],
  //   queryFn: () =>
  //     getNews({ slug: NEWS_TYPE.PODCAST, offset: "0", limit: "2" }),
  //   select(data) {
  //     return data?.data.docs;
  //   },
  // });

  // const { data: GeneralNewsList } = useQuery({
  //   queryKey: [QUERY_KEYS.news.list, NEWS_TYPE.GENERAL],
  //   queryFn: () =>
  //     getNews({ slug: NEWS_TYPE.PODCAST, offset: "0", limit: "5" }),
  //   select(data) {
  //     return data?.data.docs;
  //   },
  // });

  // const { data: PopularNewsList } = useQuery({
  //   queryKey: [QUERY_KEYS.news.list, NEWS_TYPE.POPULAR],
  //   queryFn: () =>
  //     getNews({ slug: NEWS_TYPE.POPULAR, offset: "0", limit: "5" }),
  //   select(data) {
  //     return data?.data.docs;
  //   },
  // });
  const [NewsList, setNewsList] = React.useState<any>();
  console.log(NewsList);

  React.useEffect(() => {
    // Fetch News using fetch() method
    const fetchData = async () => {
      const data = await fetch("https://api.ascendworld.info/api/v1/news");
      const news_data = await data.json();
      console.log(news_data);
      setNewsList(news_data.data);
    }
    fetchData();
  },[])

  return (
    <PodcastSecWrapper>
      <Container fixed>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3.5}>
            <Box className="podcast_inr">
              <Typography variant="h2">All Feeds </Typography>
              {NewsList?.slice(0,2).map((news:any) => {
                return (
                  // <CommonCard
                  //   key={news?.id}
                  //   image={
                  //     news?.cover_image
                  //       ? `${MEDIA_URL_SERVER2}/news/${news?.cover_image}`
                  //       : assest.cardImage7
                  //   }
                  //   tag={news?.NewsCategoryRelation?.title}
                  //   title={news?.title}
                  //   description={news?.description}
                  //   date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                  //   id={`${ROUTES.news.details}/${news?.id}`}
                  // />
                  <CommonCard
                image={
                  news?.newsImage
                    ? news?.newsImage
                    : assest.cardImage7
                }
                tag={news?.category ?? ""}
                title={news?.title}
                date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                id={`${ROUTES.news.details}/${news.id}`}
                description={news?.content}
              />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box className="podcast_inr">
              <Typography variant="h2">Latest </Typography>
              {NewsList?.slice(2,7).map((news:any) => (
                <BaseNewsCard
                  key={news.id}
                  image={
                    news?.newsImage
                      ? news?.newsImage
                      : assest.cardImage7
                  }
                  tag={news?.category ?? ""}
                  title={news?.title}
                  date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                  id={`${ROUTES.news.details}/${news?.id}`}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={3.5}>
            <Box className="podcast_inr">
              <Typography variant="h2">Popular </Typography>
              {NewsList?.slice(7,12).map((news:any) => (
                <BaseNewsCard
                  key={news?.id}
                  tag={news?.category ?? ""}
                  title={news?.title}
                  date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                  id={`${ROUTES.news.details}/${news?.id}`}
                  onlyText
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </PodcastSecWrapper>
  );
}

const PodcastSecWrapper = styled(Box)`
  padding-bottom: 85px;
  @media (max-width: 899px) {
    padding-bottom: 65px;
  }
  @media (max-width: 479px) {
    padding-bottom: 40px;
  }

  .card_wrap {
    margin-bottom: 35px;
  }
  h2 {
    color: ${primaryColors.primary};
    font-size: 24px;
    border-bottom: 1px solid ${primaryColors.colorC4C4C4};
    margin-bottom: 25px;
    padding-bottom: 25px;
  }
`;
