import AdvertisementSec from "components/AdvertisementSec/AdvertisementSec";
import BigText from "components/BigText/BigText";
import NewsLetter from "components/NewsLetter/NewsLetter";
import PodcastSec from "components/PodcastSec/PodcastSec";
import RecentNews from "components/RecentNews/RecentNews";
import Wrapper from "Layout/Wrapper/Wrapper";
import NewsBanner from "components/NewsBanner/NewsBanner";
import LatestNews from "components/LatestNews/LatestNews";
import assest from "json/assest";

export default function Home() {
  return (
    <Wrapper>
      <BigText />
      <NewsBanner />
      <RecentNews />
      <AdvertisementSec img={assest.advertiseImage} />
      <LatestNews />
      <PodcastSec />
      <NewsLetter />
    </Wrapper>
  );
}

// import AdvertisementSec from "components/AdvertisementSec/AdvertisementSec";
// import BigText from "components/BigText/BigText";
// import NewsLetter from "components/NewsLetter/NewsLetter";
// import PodcastSec from "components/PodcastSec/PodcastSec";
// import RecentNews from "components/RecentNews/RecentNews";
// import Wrapper from "Layout/Wrapper/Wrapper";
// import NewsBanner from "components/NewsBanner/NewsBanner";
// import LatestNews from "components/LatestNews/LatestNews";
// import assest from "json/assest";

// export default function Home() {
//   return (
//     <Wrapper>
//       <BigText />
//       {/* Add a margin to ensure content is not hidden behind the fixed BigText */}
//       <div style={{ marginTop: '150px' }}> {/* Adjust the margin as per the height of the BigText */}
//         <NewsBanner />
//         <RecentNews />
//         <AdvertisementSec img={assest.advertiseImage} />
//         <LatestNews />
//         <PodcastSec />
//         <NewsLetter />
//       </div>
//     </Wrapper>
//   );
// }

