import assest from "../assest";

export const demo = [
  {
    name: "asdfasdf",
  },
];

export const cardMock = [
  {
    image: assest.cardImage1,
    tag: "Photo",
  },

  {
    image: assest.cardImage2,
    tag: "Politics",
  },
];
export const cardMock4 = [
  {
    image: assest.cardImage3,
    tag: "Photo",
  },

  {
    image: assest.cardImage4,
    tag: "Family",
  },
  {
    image: assest.cardImage5,
    tag: "Science",
  },
  {
    image: assest.cardImage6,
    tag: "Politics",
  },
];

export const cardMock2 = [
  {
    image: assest.smallCardImage1,
    tag: "Family",
    title: "Orci varius natoque penatibus et magnis dis parturient",
  },

  {
    image: assest.smallCardImage2,
    tag: "Science",
    title: "Orci varius natoque penatibus et magnis dis parturient",
  },
  {
    image: assest.smallCardImage3,
    tag: "Education",
    title: "Orci varius natoque penatibus et magnis dis parturient",
  },
  {
    image: assest.smallCardImage4,
    tag: "Business",
    title: "Orci varius natoque penatibus et magnis dis parturient",
  },
  {
    image: assest.smallCardImage4,
    tag: "Science",
    title: "Orci varius natoque penatibus et magnis dis parturient",
  },
];
export const cardMock5 = [
  {
    id: "1",
    image: assest.smallCardImage1,
    tag: "Family",
    title: "Lorem ipsum dolor sit amet, consectet",
  },
  {
    id: "1",
    image: assest.smallCardImage2,
    tag: "Science",
    title: "Lorem ipsum dolor sit amet, consectet",
  },
  {
    id: "1",
    image: assest.smallCardImage3,
    tag: "Education",
    title: "Lorem ipsum dolor sit amet, consectet",
  },
  {
    id: "1",
    image: assest.smallCardImage4,
    tag: "Business",
    title: "Lorem ipsum dolor sit amet, consectet",
  },
];

export const cardMock3 = [
  {
    image: assest.smallCardImage1,
    tag: "Global",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },

  {
    image: assest.smallCardImage2,
    tag: "Photo",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.smallCardImage3,
    tag: "Fiction",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.smallCardImage4,
    tag: "Family",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.smallCardImage4,
    tag: "Family",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
];
export const moreNews = [
  {
    image: assest.more1,
    tag: "Family",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more2,
    tag: "Science",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more3,
    tag: "Education",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more4,
    tag: "Business",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more5,
    tag: "Science",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
];
export const moreNewsTwo = [
  {
    image: assest.more1,
    tag: "Family",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more7,
    tag: "Science",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more8,
    tag: "Education",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more9,
    tag: "Business",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.more10,
    tag: "Science",
    id: "1",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
];

export const cardMock3New = [
  {
    image: assest.smallCardImage1,
    tag: "Global",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },

  {
    image: assest.smallCardImage2,
    tag: "Photo",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.smallCardImage3,
    tag: "Fiction",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
  {
    image: assest.smallCardImage4,
    tag: "Family",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit integer.",
  },
];

export const bigMenuLIst1 = [
  "Politics",
  "Ideas",
  "Technology",
  "Business",
  "Global",
  "Health",
];
export const bigMenuLIst3 = ["Progress", "Fiction", "Photo", "Audio", "Events"];
export const bigMenuLIst2 = [
  "Features",
  "Science",
  "Culture",
  "Books",
  "Education",
  "Family",
];
export const premiumCard = [
  {
    title: "Silver",
    price: "$60",
    classname: true,
  },
  {
    title: "Gold",
    price: "$100",
    isDeep: true,
  },
  {
    title: "Platinum",
    price: "$150",
    classname: true,
  },
];

export const activeNewsTabCardData = [
  {
    img: assest.ActiveNewsImg1,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg2,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg3,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg4,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg5,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg6,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg7,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
  {
    img: assest.ActiveNewsImg8,
    chipTxt: "Active",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    icontitle: "Jhon Due",
  },
];

export const pastNewsTabCardData = [
  {
    img: assest.PastNewsTabImg1,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg2,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg3,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg4,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg5,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg6,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg7,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
  {
    img: assest.PastNewsTabImg8,
    chipTxt: "Posted",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    titleicon: "110",
  },
];

export const rejectedTabCardData = [
  {
    img: assest.rejectedNewsTabImg1,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg2,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg3,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg4,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg5,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg6,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg7,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
  {
    img: assest.rejectedNewsTabImg8,
    chipTxt: "Rejected",
    title:
      "Lorem ipsum dolor sit metconsectetur adipiscing elit. Curabitur sollicitudin.",
    rejectedtitle: "Lorem Ipsum is simply dummy title.",
  },
];

export const myPostedNewsData = [
  {
    img: assest.myPostedNewsImg1,
    txt: "Morbi euismod lobortis odio, rutrum viverra diam elementum ut.",
    date: "Dec 9, 24",
    assinged: "Assigned to Anita Letterback",
    statusClassName: "app",
    satus: "Approved",
  },
  {
    img: assest.myPostedNewsImg2,
    txt: "Morbi euismod lobortis odio, rutrum viverra diam elementum ut.",
    date: "Dec 9, 24",
    assinged: "Assigned to Jhon Due",
    statusClassName: "pen",
    satus: "Pending",
  },
  {
    img: assest.myPostedNewsImg3,
    txt: "Morbi euismod lobortis odio, rutrum viverra diam elementum ut.",
    date: "Dec 9, 24",
    assinged: "Assigned to Jhon Due",
    statusClassName: "rej",
    satus: "Rejected",
  },
  {
    img: assest.myPostedNewsImg4,
    txt: "Morbi euismod lobortis odio, rutrum viverra diam elementum ut.",
    date: "Dec 9, 24",
    assinged: "Assigned to Anita Letterback",
    statusClassName: "app",
    satus: "Approved",
  },
  {
    img: assest.myPostedNewsImg5,
    txt: "Morbi euismod lobortis odio, rutrum viverra diam elementum ut.",
    date: "Dec 9, 24",
    assinged: "Assigned to Anita Letterback",
    statusClassName: "app",
    satus: "Approved",
  },
  {
    img: assest.myPostedNewsImg6,
    txt: "Morbi euismod lobortis odio, rutrum viverra diam elementum ut.",
    date: "Dec 9, 24",
    assinged: "Assigned to Jhon Due",
    statusClassName: "app",
    satus: "Approved",
  },
];
export const tableData = [
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "Dee End",
    chip: "Pending",
    className: "pen",
  },
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "Bea Mine",
    chip: "Pending",
    className: "pen",
  },
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "Audie Yose",
    chip: "Approved",
    className: "app",
  },
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "Frank Furter",
    chip: "Approved",
    className: "app",
  },
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "Willie Makit",
    chip: "Rejected",
    className: "rej",
  },
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "John Dory",
    chip: "Approved",
    className: "app",
  },
  {
    img: assest.table1,
    news: "Lorem ipsum dolor sit amet, consectetur adipiscing...",
    date: "Dec 9, 24",
    time: "14.00",
    reportName: "Dee Zynah",
    chip: "Rejected",
    className: "rej",
  },
];
export const pendingData = [
  {
    image: assest.pending1,
    title: "Maecenas et nisi ut leo tempor porta. Nam vel",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    image: assest.pending2,
    title: "Maecenas et nisi ut leo tempor porta. Nam vel",
    subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];
