import { Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import AuthWrap from "components/AuthWrap/AuthWrap";
import { LoginDataStyle } from "pages/auth/login";
import React from "react";
import { Link } from "react-router-dom";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import CheckedIcon from "ui/Icons/CheckedIcon";
import UNcheckedIcon from "ui/Icons/UNcheckedIcon";

const EicLogin = () => {
  return (
    <AuthWrap
      headingText="Welcome to WNO"
      subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    >
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="abcd1922@gmail.com"
            isHeading
            inputHead="Email Address"
          />
        </Grid>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="************"
            isHeading
            inputHead="Password"
          />
        </Grid>
      </Grid>
      <LoginDataStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ margin: "20px 0 0" }}
          flexWrap="wrap"
        >
          <FormControlLabel
            className="checked"
            control={
              <Checkbox
                icon={<UNcheckedIcon />}
                checkedIcon={<CheckedIcon />}
                disableRipple
              />
            }
            label="Remember me"
          />
          <Link to="/auth/forgot-password" className="forgot">
            Forgot Password?
          </Link>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          className="btn_stack"
        >
          <CustomButtonPrimary variant="contained" color="primary">
            Log In
          </CustomButtonPrimary>
        </Stack>
      </LoginDataStyle>
    </AuthWrap>
  );
};

export default EicLogin;
