import { Grid, Stack } from "@mui/material";
import AuthWrap from "components/AuthWrap/AuthWrap";
import { LoginDataStyle } from "pages/auth/login";
import React from "react";
import { useNavigate } from "react-router-dom";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";

const ReporterResetPassword = () => {
  const navigate = useNavigate();
  return (
    <AuthWrap
      headingText="Reset Password"
      subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    >
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="************"
            isHeading
            inputHead="New Password"
          />
        </Grid>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="************"
            isHeading
            inputHead="Confirm Password"
          />
        </Grid>
      </Grid>
      <LoginDataStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          className="btn_stack"
        >
          <CustomButtonPrimary variant="contained" color="primary" onClick={() => navigate('/dashboard')}>
            Submit
          </CustomButtonPrimary>
        </Stack>
      </LoginDataStyle>
    </AuthWrap>
  );
};

export default ReporterResetPassword;
