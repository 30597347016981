import EicNewsManagementSec from "components/EicNewsManagementSec/EicNewsManagementSec";
import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";
import React from "react";

const NewsManagementDetails = () => {
  return (
    <DashboardWrapper headerTitle={"News Management"} isEic>
      <EicNewsManagementSec />
    </DashboardWrapper>
  );
};

export default NewsManagementDetails;
