import { Box, Grid, Stack, Typography, styled } from '@mui/material'
import { primaryColors } from 'Theme/_muiPalette'
import AuthWrap from 'components/AuthWrap/AuthWrap'
import React from 'react'
import { Link } from 'react-router-dom'
import InputFieldCommon from 'ui/CommonInput/CommonInput'
import CustomButtonPrimary from 'ui/CustomButtons/CustomButtonPrimary'
export const OtpVerifiactionWrap = styled(Box)`
max-width: 296px;
margin: 0 auto;
.otp_input{
    .MuiInputBase-root{
        padding-left: 25px;
        @media (max-width:329px) {
            padding-left: 20px;
        }
    }
}
.btn_stack{
    button{
        min-width: 190px !important;
        margin-top: 30px;
    }
}
.sing_up{
    margin-top: 20px;
    text-align: center;
    color: ${primaryColors.mainFontColor};
    font-size: 14px;
    a{
        color: ${primaryColors.primary};
        :hover{
            color: ${primaryColors.mainFontColor};
        }
    }
}

`
const OtpVerifiaction = () => {
  return (
    <AuthWrap headingText="Enter OTP" subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit">
        <OtpVerifiactionWrap>
            <Grid container spacing={2.5}>
                <Grid item xs={3}>
                    <InputFieldCommon placeholder='8' className='otp_input'/>
                </Grid>
                <Grid item xs={3}>
                    <InputFieldCommon placeholder='2' className='otp_input'/>
                </Grid>
                <Grid item xs={3}>
                    <InputFieldCommon placeholder='4' className='otp_input'/>
                </Grid>
                <Grid item xs={3}>
                    <InputFieldCommon placeholder='6' className='otp_input'/>
                </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="center" className='btn_stack'>
            <CustomButtonPrimary variant='contained' color='primary'>
            Continue
            </CustomButtonPrimary>
            </Stack>
            <Typography variant="body1" className="sing_up">
            Don’t received OTP ?  <Link to="#">Resend</Link>
            </Typography>
        </OtpVerifiactionWrap>
    </AuthWrap>
  )
}

export default OtpVerifiaction