import DashboardWrapper from 'Layout/DashboardWrapper/DashboardWrapper'
import RejectedNews from 'components/RejectedNewsDetails/RejectedNewsDetails'
import React from 'react'

const RejectedNewsDetails = () => {
  return (
    <DashboardWrapper headerTitle="My News" >
    <RejectedNews/>
</DashboardWrapper>
  )
}

export default RejectedNewsDetails