import React from "react";
import { Box, Container, styled } from "@mui/material";
import AdvertisementSec from "components/AdvertisementSec/AdvertisementSec";
import NewsBanner from "components/NewsBanner/NewsBanner";
import NewsLetter from "components/NewsLetter/NewsLetter";
import PopularNewsSec from "components/PopularNewsSec/PopularNewsSec";
import assest from "json/assest";
import Wrapper from "Layout/Wrapper/Wrapper";

const PopularNews = () => {
  return (
    <Wrapper>
      <NewsBanner />
      <PopularNewsSec />
      <AboutWrap>
        <Container fixed>
          <Box className="addsTwo_wrap">
            <AdvertisementSec img={assest.doctor} />
          </Box>
        </Container>
      </AboutWrap>
      <PopularNewsSec isHeader isGap />
      <NewsLetter />
    </Wrapper>
  );
};

export default PopularNews;

const AboutWrap = styled(Box)`
  .add_wrap {
    padding-bottom: 90px;
    border-bottom: 1px solid rgb(196, 196, 196, 0.2);
    border-top: 1px solid rgb(196, 196, 196, 0.2);
    @media (max-width: 1199px) {
      padding-bottom: 60px;
    }
    @media (max-width: 599px) {
      padding-bottom: 40px;
    }
    @media (max-width: 479px) {
      padding-bottom: 30px;
    }
  }
  .addsTwo_wrap {
    border-top: 1px solid rgb(196, 196, 196, 0.1);
  }
`;
