import React from "react";
import { Grid } from "@mui/material";
import CommonPagination from "components/CommonPagination/CommonPagination";
import { rejectedTabCardData } from "json/mock/demo.mock";
import { RejectedNewsTabStyled } from "styles/StyledComponents/RejectedNewsTabStyled";
import BaseNewsCard from "components/Cards/BaseNewsCard";

const RejectedNewsTab = () => {
  return (
    <RejectedNewsTabStyled>
      <Grid container spacing={2} className="rejectedNews">
        {rejectedTabCardData.map((item, index) => (
          <Grid item xs={12} md={6} lg={6}>
            <BaseNewsCard
              image={item.img}
              tag={item.chipTxt}
              title={item.title}
              description={item.rejectedtitle}
              id="/reporter/dashboard/rejected-news-details"
            />
          </Grid>
        ))}
      </Grid>
      <CommonPagination />
    </RejectedNewsTabStyled>
  );
};

export default RejectedNewsTab;
