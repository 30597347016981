import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { getNews } from "api/function/news.api";
import BaseNewsCard from "components/Cards/BaseNewsCard";
import dayjs from "dayjs";
import assest from "json/assest";
import React from "react";
//import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { RecentNewsWrapper } from "styles/StyledComponents/RecentNewsWrapper";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import AudioIcon from "ui/Icons/AudioIcon";
import CalenderIcon from "ui/Icons/CalenderIcon";
import FilterIcon2 from "ui/Icons/FilterIcon2";
import { MEDIA_URL_SERVER2 } from "utils/config/common.config";
import { NEWS_TYPE } from "utils/config/news.config";
import { QUERY_KEYS } from "utils/config/queryKeys.config";
import { ROUTES } from "utils/config/routePaths.config";

const RecentNews = () => {
  // const { data: NewsList } = useQuery({
  //   queryKey: [QUERY_KEYS.news.list, NEWS_TYPE.RECENT],
  //   queryFn: () => getNews({ slug: NEWS_TYPE.RECENT, offset: "0", limit: "9" }),
  //   select(data) {
  //     return data?.data.docs;
  //   },
  // });
  const [NewsList, setNewsList] = React.useState<any>();
  console.log(NewsList);

  React.useEffect(() => {
    // Fetch News using fetch() method
    const fetchData = async () => {
      const data = await fetch("https://api.ascendworld.info/api/v1/news");
      const news_data = await data.json();
      console.log(news_data);
      setNewsList(news_data.data);
    }
    fetchData();
  },[])

  return (
    <RecentNewsWrapper>
      <Container fixed>
        <Box className="recnt_hdr">
          <Typography variant="h2">Trending News</Typography>
          <Button>
            <FilterIcon2 />
          </Button>
        </Box>
        <Grid
          container
          spacing={{ lg: 4, sm: 3, xs: 2.8 }}
          rowSpacing={{ md: 7, sm: 7, xs: 5 }}
        >
          <Grid item xs={12} lg={3.8} md={6}>
            <Box className="podcast_inr">
              {NewsList?.slice(0, 4)?.map((news:any) => (
                <BaseNewsCard
                  tag={news?.category ?? ""}
                  id={`${ROUTES.news.details}/${news.id}`}
                  title={news?.title}
                  date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                />
              ))}
            </Box>
          </Grid>

          {/* Middle Content */}
          <Grid item xs={12} lg={4.4} md={6}>
            <Box className="podcast_in_new">
              <figure>
                <Link to="/">
                  <img
                    src={
                      NewsList?.[4]?.newsImage
                        ? NewsList?.[4]?.newsImage
                        : assest.cardImage7
                    }
                    alt="banner"
                  />
                </Link>
              </figure>
              <span className="tag">
                {NewsList?.[4]?.category}
              </span>
              <Typography variant="h3">
                {NewsList?.[4]?.title ? (
                  <Link to={NewsList?.[4]?.id}>
                    {NewsList?.[4]?.title}
                  </Link>
                ) : (
                  <Skeleton variant="text" height={36} animation="wave" />
                )}
              </Typography>

              <Box className="card_bottom">
                <List disablePadding>
                  <ListItem disablePadding>
                    <CalenderIcon />
                    {dayjs(NewsList?.[4]?.createdAt).format("MMMM DD, YYYY")}
                  </ListItem>
                  <ListItem disablePadding>
                    <AudioIcon />
                    Audio
                  </ListItem>
                </List>
              </Box>

              {/* <Typography variant="body1">
                {NewsList?.[4]?.content ? (
                  NewsList?.[4]?.content
                ) : (
                  <Skeleton variant="text" height={36} animation="wave" />
                )}
              </Typography> */}
              <Typography
                  variant="body1"
                  className="long_texts"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      Number(NewsList?.[4]?.content?.length) >= 200
                        ? `${NewsList?.[4]?.content.slice(0, 200)}...`
                        : NewsList?.[4]?.content
                    }`,
                  }}
                />

                <Link to={`/news-details/${NewsList?.[4]?.id}`} style={{ textDecoration: 'none' }}>
                  <CustomButtonPrimary color="primary" variant="contained">
                    Read More
                  </CustomButtonPrimary>
                </Link>
            </Box>
          </Grid>

          <Grid item xs={12} lg={3.8} md={12}>
            <Box className="podcast_inr">
              {NewsList?.slice(5, 9).map((news:any) => (
                <BaseNewsCard
                  id={`${ROUTES.news.details}/${news.id}`}
                  title={news.title}
                  tag={news?.category ?? ""}
                  image={
                    news?.newsImage
                      ? news?.newsImage
                      : assest.cardImage7
                  }
                  date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </RecentNewsWrapper>
  );
};

export default RecentNews;
