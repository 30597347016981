
import DashboardWrapper from 'Layout/DashboardWrapper/DashboardWrapper'
import EicNewsManagementSec from 'components/EicNewsManagementSec/EicNewsManagementSec'
import React from 'react'
const PastnewsDetails = () => {
  return (
    <DashboardWrapper headerTitle="My News" >
        <EicNewsManagementSec isStatus isButtons isDetails Author='Bea Mine'/>
    </DashboardWrapper>
  )
}

export default PastnewsDetails