import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import { Link } from "react-router-dom";
//import { useQuery } from "react-query";
import dayjs from "dayjs";
import CommonCard from "../Cards/CommonCard";

import { getNews } from "api/function/news.api";
import { NEWS_TYPE } from "utils/config/news.config";
import { QUERY_KEYS } from "utils/config/queryKeys.config";

import assest from "json/assest";
import { MEDIA_URL_SERVER2 } from "utils/config/common.config";
import { ROUTES } from "utils/config/routePaths.config";
import React from "react";

export default function LatestNews() {
  // const { data: NewsList } = useQuery({
  //   queryKey: [QUERY_KEYS.news.list, NEWS_TYPE.LATEST],
  //   queryFn: () => getNews({ slug: NEWS_TYPE.RECENT, offset: "0", limit: "4" }),
  //   select(data) {
  //     return data?.data.docs;
  //   },
  // });
  const [NewsList, setNewsList] = React.useState<any>();
  console.log(NewsList);

  React.useEffect(() => {
    // Fetch News using fetch() method
    const fetchData = async () => {
      const data = await fetch("https://api.ascendworld.info/api/v1/news");
      const news_data = await data.json();
      console.log(news_data);
      setNewsList(news_data.data);
    }
    fetchData();
  },[])

  return (
    <LatestSecWrapper>
      <Container fixed>
        <Box className="hdr_top">
          <Typography variant="h2">Top Stories </Typography>
          <Link to="/">View all</Link>
        </Box>

        <Grid container spacing={2}>
          {NewsList?.slice(0,8).map((news:any) => (
            <Grid item xs={12} md={3} key={news.id}>
              <CommonCard
                image={
                  news?.newsImage
                    ? news?.newsImage
                    : assest.cardImage7
                }
                tag={news?.category ?? ""}
                title={news?.title}
                date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                id={`${ROUTES.news.details}/${news.id}`}
                description={news?.content}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </LatestSecWrapper>
  );
}

const LatestSecWrapper = styled(Box)`
  padding: 85px 0;
  @media (max-width: 899px) {
    padding: 80px 0;
  }
  @media (max-width: 479px) {
    padding: 40px 0;
  }
  .hdr_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    a {
      color: ${primaryColors.primary};
      font-size: 16px;
      &:hover {
        color: ${primaryColors.black};
      }
    }
    h2 {
      color: ${primaryColors.primary};
      font-size: 24px;
    }
  }
`;
