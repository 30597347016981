import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";
import BaseNewsCard from "components/Cards/BaseNewsCard";
import { moreNews, moreNewsTwo } from "json/mock/demo.mock";
import dayjs from "dayjs";
import assest from "json/assest";
import { ROUTES } from "utils/config/routePaths.config";
import { Link } from "react-router-dom";

const MoreNews = () => {
  const [NewsList, setNewsList] = React.useState<any>();

  React.useEffect(() => {
    // Fetch News using fetch() method
    const fetchData = async () => {
      const data = await fetch("https://api.ascendworld.info/api/v1/news");
      const news_data = await data.json();
      setNewsList(news_data.data);
    }
    fetchData();
  },[])
  return (
    <MoreNewsWrap>
      <Container fixed>
        <br/>
        <Typography variant="h2">Most Popular News</Typography>
        <Box className="lower_sec">
          <Grid container spacing={7}>
            <Grid item xs={12} md={6}>
              {/* {moreNews.map((item) => (
                <BaseNewsCard {...item} />
              ))} */}
              {NewsList?.slice(2,7).map((news:any) => (
                // <BaseNewsCard
                //   key={news.id}
                //   image={
                //     news?.newsImage
                //       ? news?.newsImage
                //       : assest.cardImage7
                //   }
                //   tag={news?.category ?? ""}
                //   title={news?.title}
                //   date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                //   id={`${ROUTES.news.details}/${news?.id}`}
                // />
                
              <Link key={news.id} to={`${ROUTES.news.details}/${news?.id}`}>
                <BaseNewsCard
                  image={
                    news?.newsImage
                      ? news?.newsImage
                      : assest.cardImage7
                  }
                  tag={news?.category ?? ""}
                  title={news?.title}
                  date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                  id={`${ROUTES.news.details}/${news?.id}`}
                />
              </Link>

              ))}
            </Grid>
            <Grid item xs={12} md={6}>
            {NewsList?.slice(7,12).map((news:any) => (
                // <BaseNewsCard
                //   key={news.id}
                //   image={
                //     news?.newsImage
                //       ? news?.newsImage
                //       : assest.cardImage7
                //   }
                //   tag={news?.category ?? ""}
                //   title={news?.title}
                //   date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                //   id={`${ROUTES.news.details}/${news?.id}`}
                // />
                <Link key={news.id} to={`${ROUTES.news.details}/${news?.id}`}>
                <BaseNewsCard
                  image={
                    news?.newsImage
                      ? news?.newsImage
                      : assest.cardImage7
                  }
                  tag={news?.category ?? ""}
                  title={news?.title}
                  date={dayjs(news?.createdAt).format("MMM DD, YYYY")}
                  id={`${ROUTES.news.details}/${news?.id}`}
                />
              </Link>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </MoreNewsWrap>
  );
};

export default MoreNews;

export const MoreNewsWrap = styled(Box)`
  padding-bottom: 90px;
  @media (max-width: 1199px) {
    padding-bottom: 70px;
  }
  @media (max-width: 899px) {
    padding-bottom: 60px;
  }
  @media (max-width: 599px) {
    padding-bottom: 50px;
  }
  h2 {
    font-size: 24px;
    color: ${primaryColors.primary1};
    margin-bottom: 30px;
  }
  .lower_sec {
    padding-top: 50px;
    border-top: 1px solid rgb(196, 196, 196, 0.2);
  }
`;
