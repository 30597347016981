import { createSlice } from "@reduxjs/toolkit";
import { toggleSliceData } from "../interfaces/interfaces";

const initialState: toggleSliceData = {
  isToggle: false,
};

export const toggleSlice = createSlice({
  name: "userSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setToggleData: (state, { payload }: { payload: boolean }) => {
      state.isToggle = payload;
    },
  },
  extraReducers: {},
});

export const { setToggleData } = toggleSlice.actions;

export default toggleSlice.reducer;
