import React from 'react'
import { Grid, Stack } from '@mui/material'
import AuthWrap from 'components/AuthWrap/AuthWrap'
import InputFieldCommon from 'ui/CommonInput/CommonInput'
import { LoginDataStyle } from './login'
import CustomButtonPrimary from 'ui/CustomButtons/CustomButtonPrimary'

const ResetPassword = () => {
  return (
    <AuthWrap headingText="Reset Password" subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit">
    <Grid container spacing={2.5}>
        <Grid item xs={12} >
        <InputFieldCommon placeholder="************" isHeading inputHead="New Password"/>
        </Grid>
        <Grid item xs={12} >
        <InputFieldCommon placeholder="************" isHeading inputHead="Confirm Password"/>
        </Grid>
        </Grid>
        <LoginDataStyle>
        <Stack direction="row" alignItems="center" justifyContent="center" className='btn_stack'>
        <CustomButtonPrimary variant='contained' color='primary'>
        Submit
        </CustomButtonPrimary>
        </Stack>
        </LoginDataStyle>
   
</AuthWrap>
  )
}

export default ResetPassword