import { Box, styled } from "@mui/material";

export const EicProfileWrap = styled(Box)`
  display: flex;
  .lftBox {
    border-right: 1px solid rgb(196, 196, 196, 0.2);
    padding: 30px 0;

    .figure_wrap {
      width: 250px;
      display: flex;
      justify-content: center;
    }
    h3 {
      text-align: center;
      margin-top: 20px;
    }

    figure {
      width: 140px;
      height: 140px;
      line-height: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .rgtBox {
    padding-left: 70px;
    padding-top: 50px;
    width: calc(100% - 275px);
    @media (max-width: 899px) {
       width: 100%;
       padding-left: 0;
      }
    .heading_stack {
      padding-bottom: 33px;
      margin-bottom: 30px;
      width: 100%;
      border-bottom: 1px solid rgb(196, 196, 196, 0.2);
      .edit_butt {
        padding: 0;
        min-width: fit-content;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: rgb(37, 150, 190, 0.1);
        :hover {
          background-color: rgb(37, 150, 190, 0.2);
        }
      }
    }
    h2 {
      font-size: 24px;
    }
    .form {
      max-width: 630px;
    }
    .passWord_Butt {
      margin-top: 40px;
    }
  }
`;
