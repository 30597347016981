/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, Stack, Typography } from '@mui/material'
import AuthWrap from 'components/AuthWrap/AuthWrap'
import React, { useState } from 'react'
import InputFieldCommon from 'ui/CommonInput/CommonInput'
import { LoginDataStyle } from './login'
import CustomButtonPrimary from 'ui/CustomButtons/CustomButtonPrimary'
import { useNavigate } from 'react-router-dom';
import MuiModalWrapper from 'components/CommonModal/CommonModal'
import { LinkModalWrap } from 'styles/StyledComponents/ModalStyled'
import assest from 'json/assest'


const ForgotPassword = () => {
    const navigate = useNavigate();
    const [open,setOpen]=useState(false)
    const handleOpen = ()=>{
        setOpen(!open)
    }
    const handleClose =()=>{
        setOpen(false)
    }
    
  return (
    <AuthWrap headingText="Forgot Password" subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit">
        <Grid container>
            <Grid item xs={12} >
            <InputFieldCommon placeholder="abcd1922@gmail.com" isHeading inputHead="Email Address"/>
            </Grid>
            </Grid>
            <LoginDataStyle>
            <Stack direction="row" alignItems="center" justifyContent="center" className='btn_stack' >
            <CustomButtonPrimary variant='contained' color='primary'  onClick={handleOpen}>
            Continue
            </CustomButtonPrimary>
            </Stack>
            </LoginDataStyle>
            <MuiModalWrapper onClose={handleClose} open={open} isClose>
                <LinkModalWrap>
                <i>
                    <img src={assest.mail_icon} alt="" />
                </i>
                <Typography variant="h3">
                We have sent a link to your email 
                </Typography>
                <Typography variant="body1">
                Lorem ipsum dolor sit amet consectetur. Congue sed sed eget id blandit pretium penatibus quam.
                </Typography>
                <CustomButtonPrimary variant="contained" color="primary" className="modla_button" onClick={() => navigate('/auth/reset-password')}>
                Continue
                </CustomButtonPrimary>
                </LinkModalWrap>
            </MuiModalWrapper>
    </AuthWrap>
    )
}

export default ForgotPassword