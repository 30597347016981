import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import CommonChip from "../CommonChip/CommonChip";
import { BannerWrap } from "styles/StyledComponents/BannerWrap";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import Calender from "ui/Icons/Calender";
import Music from "ui/Icons/Music";
//import { useQuery } from "react-query";
import { QUERY_KEYS } from "utils/config/queryKeys.config";
import { getNews } from "api/function/news.api";
import { NEWS_TYPE } from "utils/config/news.config";
import dayjs from "dayjs";
import { MEDIA_URL_SERVER2 } from "utils/config/common.config";
import assest from "json/assest";
import React from "react";
import { Link } from "react-router-dom";

const NewsBanner = () => {
  // const { data: NewsList } = useQuery({
  //   queryKey: [QUERY_KEYS.news.list, NEWS_TYPE.HERO],
  //   queryFn: () => getNews({ slug: NEWS_TYPE.HERO, offset: "0", limit: "1" }),
  //   select(data) {
  //     return data?.data.docs?.[0];
  //   },
  // });
  const [NewsList, setNewsList] = React.useState<any>();

  React.useEffect(() => {
    // Fetch News using fetch() method
    const fetchData = async () => {
      const data = await fetch("https://api.ascendworld.info/api/v1/news");
      const news_data = await data.json();
      console.log(news_data);
      setNewsList(news_data.data[news_data.data.length-1]);
    }
    fetchData();
  },[])

  return (
    <BannerWrap>
      <Container fixed>
        <Box className="main_banner">
          <Grid
            container
            alignItems="center"
            spacing={{ lg: 6.5, sm: 4, xs: 2 }}
          >
            <Grid item md={6}>
              <Box className="rgt_box">
                <CommonChip
                  title={NewsList?.category ?? ""}
                />
                <Typography variant="h1">
                  {NewsList?.title ? (
                    NewsList?.title
                  ) : (
                    <Skeleton variant="text" animation="wave" height={42} />
                  )}
                </Typography>
                <Box className="muisc_box">
                  <Typography variant="body1">
                    <Calender />
                    {NewsList?.createdAt
                      ? dayjs(NewsList?.createdAt).format("MMMM DD, YYYY")
                      : ""}
                  </Typography>
                  <Typography variant="body1">
                    <Music />
                    Audio
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  className="long_texts"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      Number(NewsList?.content?.length) >= 350
                        ? `${NewsList?.content.slice(0, 350)}...`
                        : NewsList?.content
                    }`,
                  }}
                />

                {/* <CustomButtonPrimary color="primary" variant="contained">
                  Read More
                </CustomButtonPrimary> */}

                <Link to={`/news-details/${NewsList?.id}`} style={{ textDecoration: 'none' }}>
                  <CustomButtonPrimary color="primary" variant="contained">
                    Read More
                  </CustomButtonPrimary>
                </Link>

              </Box>
            </Grid>

            <Grid item md={6}>
              <figure>
                <img
                  alt="banner-img"
                  src={
                    NewsList?.newsImage
                      ? NewsList?.newsImage
                      : assest.bannerIMg
                  }
                  width={515}
                  height={400}
                />
                {/* Add the image courtesy below the image */}
                {NewsList?.newsCourtesy ? (
                  <figcaption>
                    <Typography variant="caption" display="block" color="textSecondary" marginTop={1}>
                      Image courtesy: {NewsList.newsCourtesy}
                    </Typography>
                  </figcaption>
                ):(
                  <figcaption>
                    <Typography variant="caption" display="block" color="textSecondary" marginTop={1}>
                    Image courtesy: Image courtesy of our news partners.
                    </Typography>
                  </figcaption>
                )
                }
              </figure>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </BannerWrap>
  );
};

export default NewsBanner;
