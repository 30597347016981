import { Grid, Stack, Typography } from "@mui/material";
import AuthWrap from "components/AuthWrap/AuthWrap";
import MuiModalWrapper from "components/CommonModal/CommonModal";
import assest from "json/assest";
import { LoginDataStyle } from "pages/auth/login";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { LinkModalWrap } from "styles/StyledComponents/ModalStyled";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";

const EicForgotPassword = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <AuthWrap
      headingText="Forgot Password"
      subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
    >
      <Grid container>
        <Grid item xs={12}>
          <InputFieldCommon
            placeholder="abcd1922@gmail.com"
            isHeading
            inputHead="Email Address"
          />
        </Grid>
      </Grid>
      <LoginDataStyle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          className="btn_stack"
        >
          <CustomButtonPrimary
            variant="contained"
            color="primary"
            onClick={handleOpen}
          >
            Continue
          </CustomButtonPrimary>
        </Stack>
      </LoginDataStyle>
      <MuiModalWrapper onClose={handleClose} open={open} isClose>
        <LinkModalWrap>
          <i>
            <img src={assest.mail_icon} alt="" />
          </i>
          <Typography variant="h3">
            We have sent a link to your email
          </Typography>
          <Typography variant="body1">
            Lorem ipsum dolor sit amet consectetur. Congue sed sed eget id
            blandit pretium penatibus quam.
          </Typography>
          <CustomButtonPrimary
            variant="contained"
            color="primary"
            className="modla_button"
            onClick={() => navigate("/user/auth/reporter/forgotPassword")}
          >
            Continue
          </CustomButtonPrimary>
        </LinkModalWrap>
      </MuiModalWrapper>
    </AuthWrap>
  );
};

export default EicForgotPassword;
