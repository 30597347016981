const LoveIcon = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4825 0.900104L10.4825 0.899912L10.4763 0.900013C9.84806 0.91028 9.23382 1.09454 8.69567 1.43376C8.20776 1.74131 7.79822 2.16624 7.5 2.67192C7.20178 2.16624 6.79224 1.74131 6.30433 1.43376C5.76618 1.09454 5.15194 0.91028 4.52367 0.900013L4.52367 0.899821L4.51748 0.900104C3.51754 0.945745 2.57661 1.40527 1.89978 2.17649C1.22349 2.9471 0.865518 3.96648 0.902622 5.01203C0.90322 6.33607 1.56539 7.7093 2.489 8.98387C3.41457 10.2611 4.61176 11.4517 5.70125 12.4118C6.20434 12.8559 6.84125 13.1 7.5 13.1C8.15875 13.1 8.79566 12.8559 9.29875 12.4118C10.3882 11.4517 11.5854 10.2611 12.511 8.98387C13.4346 7.7093 14.0968 6.33606 14.0974 5.01202C14.1345 3.96647 13.7765 2.9471 13.1002 2.17649C12.4234 1.40527 11.4825 0.945745 10.4825 0.900104ZM6.52939 11.3915L6.52925 11.3914C5.14255 10.1691 4.05447 8.97406 3.31385 7.88187C2.57169 6.78738 2.18552 5.80653 2.18552 5.01023H2.18565L2.18539 5.0051C2.14965 4.3102 2.377 3.62941 2.81659 3.11116C3.2546 2.59475 3.86757 2.2817 4.52204 2.23787C5.1765 2.2817 5.78947 2.59475 6.22748 3.11116C6.66706 3.62941 6.89442 4.3102 6.85868 5.0051L6.85855 5.00509V5.01023C6.85855 5.18607 6.92499 5.35564 7.04471 5.4814C7.16458 5.60733 7.32824 5.67906 7.5 5.67906C7.67176 5.67906 7.83542 5.60733 7.95529 5.4814C8.07501 5.35564 8.14145 5.18607 8.14145 5.01023H8.14158L8.14132 5.0051C8.10558 4.3102 8.33294 3.62941 8.77252 3.11116C9.21053 2.59475 9.8235 2.2817 10.478 2.23787C11.1324 2.2817 11.7454 2.59475 12.1834 3.11116C12.623 3.62941 12.8504 4.3102 12.8146 5.0051L12.8145 5.00509V5.01023C12.8145 5.80653 12.4283 6.78738 11.6862 7.88157C10.9455 8.97348 9.85747 10.1679 8.47078 11.3891L8.43687 11.419V11.4205C8.17013 11.643 7.83996 11.764 7.5 11.764C7.14555 11.764 6.80176 11.6325 6.52939 11.3915Z"
        fill="#2F2F30"
        stroke="#2D2D2D"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export default LoveIcon;
