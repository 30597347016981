import DashboardWrapper from 'Layout/DashboardWrapper/DashboardWrapper'
import ActiveNewsDetails from 'components/ActiveNewsDetails/ActiveNewsDetails'
import React from 'react'

const ActivenewsDetails = () => {
  return (
    <DashboardWrapper headerTitle="My News" >
        <ActiveNewsDetails/>
    </DashboardWrapper>
  )
}

export default ActivenewsDetails