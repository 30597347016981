import React from "react";

const ArrowForwordIcon = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.839844 1.41L5.41984 6L0.839844 10.59L2.24984 12L8.24984 6L2.24984 0L0.839844 1.41Z"
        fill="#A7A7A7"
      />
    </svg>
  );
};

export default ArrowForwordIcon;
