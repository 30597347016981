import { Box } from "@mui/material";
import CommonTabs from "components/CommonTabs/CommonTabs";
import DashboardWrapper from "Layout/DashboardWrapper/DashboardWrapper";

export default function ReporterNews() {
  return (
    <DashboardWrapper headerTitle={"My News"}>
      <Box className="cmn_body_white news_body">
        <CommonTabs />
      </Box>
    </DashboardWrapper>
  );
}
