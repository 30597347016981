import { Box, styled } from "@mui/material";

export const PastNewsTabStyled = styled(Box)`
  margin-top: 20px;
  .pastNews {
    .MuiBox-root {
      .MuiTypography-h3 {
        a {
          text-decoration: none;
        }
      }
    }
  }
`;
