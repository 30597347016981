import About from "pages/about/about";
import ForgotPassword from "pages/auth/forgot-password";
import Login from "pages/auth/login";
import OtpVerifiaction from "pages/auth/otp-verifiaction";

import SignUp from "pages/auth/sign-up";
import Contact from "pages/contact/contact";

import Home from "pages/home/home";
import PopularNews from "pages/popular-news/popular-news";
import NewsDetails from "pages/news-details/news-details";
import { RouteObject } from "react-router";
import ResetPassword from "pages/auth/reset-password";
import MyProfile from "pages/my-profile/my-profile";
import SavedNews from "pages/saved-news/saved-news";
import Subscription from "pages/subscription/subscription";
import Payment from "pages/payment/payment";
import ReporterLogin from "pages/user/auth/reporter-login";
import ReporterForgotPassword from "pages/user/auth/reporter-forgot-password";
import ReporterResetPassword from "pages/user/auth/reporter-reset-password";

import SubmitNews from "pages/user/dashboard/submit-news";
import DashBoardMyProfile from "pages/user/dashboard/my-profile";
import ReporterNews from "pages/user/dashboard/reporter-news";
import DashboardHome from "pages/user/dashboard";
import ActivenewsDetails from "pages/user/dashboard/active-news-details";

import EicLogin from "pages/eic/auth/eic-login";
import EicForgotPassword from "pages/eic/auth/eic-forgot-password";
import EicResetPassword from "pages/eic/auth/eic-reset-password";
import NewsManagementDetails from "pages/eic/dashboard/news-management-details";
import PastnewsDetails from "pages/user/dashboard/past-news-details";
import RejectedNewsDetails from "pages/user/dashboard/rejected-news-details";
import NewsDetailsEdit from "pages/eic/dashboard/news-details-edit";
import NewsDetailsList from "pages/eic/dashboard/news-details-list";
import DashboardHomeEic from "pages/eic/dashboard";
import EicProfile from "pages/eic/dashboard/eic-profile";
import { ROUTES } from "utils/config/routePaths.config";

const routes: RouteObject[] = [
  {
    path: ROUTES.landingPage,
    element: <Home />,
  },
  {
    path: ROUTES.about,
    element: <About />,
  },
  {
    path: ROUTES.contact,
    element: <Contact />,
  },
  {
    path: `${ROUTES.news.details}/:id`,
    element: <NewsDetails />,
  },
  {
    path: ROUTES.news.popular,
    element: <PopularNews />,
  },
  {
    path: ROUTES.news.top,
    element: <PopularNews />,
  },
  {
    path: ROUTES.auth.signup,
    element: <SignUp />,
  },
  {
    path: ROUTES.auth.signin,
    element: <Login />,
  },
  {
    path: ROUTES.auth.forgetPassword,
    element: <ForgotPassword />,
  },
  {
    path: "/auth/otp-verification",
    element: <OtpVerifiaction />,
  },
  {
    path: "/auth/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/profile",
    element: <MyProfile />,
  },
  {
    path: "saved-news",
    element: <SavedNews />,
  },
  {
    path: "/subscription",
    element: <Subscription />,
  },
  {
    path: "/payment",
    element: <Payment />,
  },
  {
    path: "/reporter/auth/login",
    element: <ReporterLogin />,
  },
  {
    path: "reporter/auth/forgot-password",
    element: <ReporterForgotPassword />,
  },
  {
    path: "reporter/auth/reset-password",
    element: <ReporterResetPassword />,
  },
  {
    path: "/reporter/dashboard/news",
    element: <ReporterNews />,
  },
  {
    path: "/reporter/dashboard/submit-news",
    element: <SubmitNews />,
  },
  {
    path: "/reporter/dashboard/my-profile",
    element: <DashBoardMyProfile />,
  },
  {
    path: "/eic/auth/eic-login",
    element: <EicLogin />,
  },
  {
    path: "/eic/auth/eic-forgot-password",
    element: <EicForgotPassword />,
  },
  {
    path: "/eic/auth/eic-reset-password",
    element: <EicResetPassword />,
  },
  {
    path: "/eic/dashboard/news-management-details",
    element: <NewsManagementDetails />,
  },
  {
    path: "/eic/dashboard/news-details-edit",
    element: <NewsDetailsEdit />,
  },
  {
    path: "/eic/dashboard/news-details-list",
    element: <NewsDetailsList />,
  },
  {
    path: "/eic/dashboard/eic-profile",
    element: <EicProfile />,
  },
  {
    path: "/reporter/dashboard",
    element: <DashboardHome />,
  },
  {
    path: "/reporter/dashboard/active-news-details",
    element: <ActivenewsDetails />,
  },
  {
    path: "/reporter/dashboard/past-news-details",
    element: <PastnewsDetails />,
  },
  {
    path: "/reporter/dashboard/rejected-news-details",
    element: <RejectedNewsDetails />,
  },
  {
    path: "/eic/dashboard",
    element: <DashboardHomeEic />,
  },
];

export default routes;
