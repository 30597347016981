import React, { useState } from "react";
import { SubmitNewsSecStyled } from "styles/StyledComponents/SubmitNewsSecStyled";
import Typography from "@mui/material/Typography";
import { Box, Grid, MenuItem } from "@mui/material";
import InputFieldCommon from "ui/CommonInput/CommonInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
import assest from "json/assest";
import CustomButtonPrimary from "ui/CustomButtons/CustomButtonPrimary";
import { ChangePassWord } from "styles/StyledComponents/ModalStyled";
import MuiModalWrapper from "components/CommonModal/CommonModal";

interface submitNewsProps {
  headTxt: string;
  onclick?: ()=> void;
}

const SubmitNewsSec = ({ headTxt }: submitNewsProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <SubmitNewsSecStyled className="cmn_body_white">
      <Typography variant="h1" className="headTxt">
        {headTxt}
      </Typography>
      <Box className="subNewsSec">
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={6}>
            <InputFieldCommon
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              isHeading
              inputHead="News Heading"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect initialValue="6A, Lorem ipsum dolor" label="Location">
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Selected">Selected</MenuItem>
            </CustomSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect initialValue="Business" label="News Category">
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Selected">Selected</MenuItem>
            </CustomSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect initialValue="Audio" label="News Mode">
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Selected">Selected</MenuItem>
            </CustomSelect>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="body1" className="input_head">
                News Details
              </Typography>
              <figure>
                <img src={assest.submitNewsImg} alt="" />
              </figure>
            </Box>
          </Grid>
        </Grid>
        <CustomButtonPrimary
          variant="contained"
          color="primary"
          onClick={handleOpen}
        >
          Submit
        </CustomButtonPrimary>
      </Box>
      <MuiModalWrapper onClose={handleClose} open={open} isClose>
        <ChangePassWord>
          <Typography variant="h3">This News is rejected</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} className="txt_area">
              <InputFieldCommon
                placeholder="Enter reason"
                isHeading
                inputHead="Reason"
                multiline
                minRows={9}
              />
            </Grid>
          </Grid>
          <CustomButtonPrimary
            variant="contained"
            color="primary"
            className="modla_button"
            fullWidth
          >
            Submit
          </CustomButtonPrimary>
        </ChangePassWord>
      </MuiModalWrapper>
    </SubmitNewsSecStyled>
  );
};

export default SubmitNewsSec;
