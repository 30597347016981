import { Box, styled } from "@mui/material";
import { primaryColors } from "Theme/_muiPalette";

export const DashboardHomeWrapper = styled(Box)`
  .top_sec {
    margin-bottom: 20px;
  }
  .filter_card {
    background: ${primaryColors.white};
    border-radius: 6px;
    padding: 15px;
    position: relative;
  }

  .filter_btm {
    display: flex;
    
    span {
      border-radius: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      width: 64px;
      /* background-color: rgba(22,192,168,.2); */
    }
  }
  .filter_rgt {
    margin-left: 12px;
    h4 {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .trend_score {
    position: absolute;
    left: 15px;
    top: 15px;
    font-weight: 400;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: ${primaryColors.color16C098};
    svg {
      margin-right: 5px;
    }
    &.down {
      color: ${primaryColors.colorD6614C};
    }
  }
  .cmn_wrap {
    background: ${primaryColors.white};
    border-radius: 6px;
    padding: 20px;
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
      padding: 20px 0;
      border-bottom: 1px solid ${primaryColors.colorC4C4C4};
    }
  }
  .news_rgt{
    height: 461px;
      overflow-y: auto;
      @media (max-width: 1199px) {
        height:auto;
        overflow: auto;
      }
    .news_rgt_wrap {
    height: 100%;
    .news_inr {
      
      min-height: 100%;
    }
  }
  }
 

  .news_inr2 {
    h3 {
      font-size: 24px;
      margin-top: 10px;
    }
  }
  .views_top {
    h3 {
      span {
        display: inline-block;
        padding-left: 10px;
        color: ${primaryColors.color16C098};
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .graph_satck {
    .MuiFormControl-root {
      max-width: 135px;
      
      
      border-radius: 6px;

      .custom_sel {
        min-height: 40px;
        padding: 8px 8px 8px 14px;
        width: 100%;
        height: 100%;
        min-width: 135px;
        border: 1px solid ${primaryColors.primary};
        .MuiSelect-select{
          color: ${primaryColors.primary};
          font-size: 16px;
          padding-right: 2px;
        }
        .MuiButtonBase-root{
         right: 14px;
         filter: brightness(0) saturate(100%) invert(42%) sepia(94%) saturate(437%) hue-rotate(149deg) brightness(100%) contrast(80%);
        }
      }
    }
  }
`;